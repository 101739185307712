import { createTheme } from '@material-ui/core/styles'


export default createTheme({
  overrides: {
    MuiTypography: {
      h4: {
        fontWeight: 700
      }
    },
    MuiFormControl: {
      marginDense: {
        marginTop: 0
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingTop: '4px !important'
      }
    },
    MuiFilledInput: {
      inputMarginDense: {
        paddingTop: '6px'
      },
      multiline: {
        '&.MuiFilledInput-marginDense': {
          paddingTop: '6px'
        }
      }
    }
  }
})
