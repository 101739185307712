export const filterOptions = [
  {
    label: 'All Units',
    value: null
  },
  {
    label: 'Promoted',
    value: unit => (unit.adStatus.campaigns.length > 0)
  },
  {
    label: 'Running',
    value: unit => !!(unit.adStatus.campaigns.find(
      campaign => (
        (campaign.attributes.servingStatus === 'SERVING') &&
        (campaign.attributes.status === 'ENABLED')
      )
    ))
  }
]


export const getFilteredUnits = (units, optionIndex) => {
  const filterFunction = filterOptions[optionIndex].value

  if (!filterFunction) {
    return units
  }

  return units.filter(filterFunction)
}
