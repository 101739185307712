import React from 'react'
import PropTypes from 'prop-types'

import { GooglePromotionStatus } from './GooglePromotionStatus'


export const Promotions = ({ campaigns = [] }) => {

  if (!campaigns.length) {
    return null
  }


  return (
    <div className="row row-cols-2 row-cols-lg-1 g-3">
      {campaigns.map((campaign, index) => (
        <div key={index} className="col">
          <GooglePromotionStatus adStatus={campaign} />
        </div>
      ))}
    </div>
  )
}


Promotions.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.object)
}
