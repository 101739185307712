import PropTypes from 'prop-types'

import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'

import { Badge } from '../..'


export const GoogleShoppingStatus = ({ skipPublishing, isShoppingApproved, isAvailableOnGoogleShopping }) => {

  if (skipPublishing) {
    return (
      <Badge variant="danger">Google</Badge>
    )
  }

  if (isShoppingApproved) {
    return (
      <Badge variant="primary">Google</Badge>
    )
  }

  if (isAvailableOnGoogleShopping) {
    return (
      <Badge variant="secondary">
        <span className="d-flex align-items-center">
          <QueryBuilderIcon style={{ fontSize: '1.2em' }} className="me-1 lh-1" />
          Google
        </span>
      </Badge>
    )
  }


  return null

}


GoogleShoppingStatus.propTypes = {
  skipPublishing: PropTypes.bool,
  isShoppingApproved: PropTypes.bool,
  isAvailableOnGoogleShopping: PropTypes.bool
}
