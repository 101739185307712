import { isRequired, isValidMail } from '../../helpers/Form'


export const loginFields = {
  email: {
    validators: [isRequired, isValidMail]
  },
  password: {
    validators: [isRequired]
  }
}


export const requestPasswordRecoveryFields = {
  email: { ...loginFields.email }
}


export const setPasswordFields = {
  password1: { ...loginFields.password },
  password2: { ...loginFields.password }
}
