import React from 'react'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightBold
  }
}))


export const LengthLimit = ({ value, limit }) => {
  const classes = useStyles()

  const length = String(value).length

  const className = (length > limit) ? classes.error : ''

  return (
    <Box textAlign="right">
      <Typography variant="body2" className={className}>
        {String(value).length}/{limit}
      </Typography>
    </Box>
  )
}
