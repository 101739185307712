import React from 'react'

import Alert from '@material-ui/lab/Alert'
import TextField from '@material-ui/core/TextField'

import { FullWidthSubmitButton } from '..'


export const RequestPasswordRecoveryForm = ({
  fields,
  isValid,
  isLoading,
  hasSuccess,
  errors,
  onSubmit
}) => {

  if (hasSuccess) {
    return (
      <div >
        <Alert severity="success" className="mt-3">
          Please check <strong>{fields.email?.getProps().value}</strong> for password recovery instructions.
        </Alert>
      </div >
    )
  }


  return (<>
    <p>We will send you a link to recover your Medvertise password</p>

    {!!errors.length && (<>
      {errors.map((errorMessage, index) => (
        <Alert key={index} severity="error" className="mt-3">
          {errorMessage}
        </Alert>
      ))}
    </>
    )}

    {!!Object.keys(fields).length && (

      <form onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}>
        <TextField
          label="Email *"
          error={!fields.email.isValid}
          autoComplete="email"
          id="email"
          name="email"
          type="email"
          className="mb-4"
          fullWidth
          variant="filled"
          InputLabelProps={{
            shrink: true
          }}
          {...fields.email.getProps()}
        />

        <FullWidthSubmitButton
          isLoading={isLoading}
          enabled={isValid}
          type="submit"
        >
          Request Password Recovery Link
        </FullWidthSubmitButton>
      </form>

    )}
  </>)
}
