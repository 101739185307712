import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { Companies as CompaniesComponent } from '../components/Companies'
import { Loader } from '../components/ui-blocks'
import { getCompanies, switchCompany } from '../helpers/mpo-wp-api'

import { useUnits, useUser } from '../contexts'


export const Companies = () => {
  const [companies, setCompanies] = useState()
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const { setUser, setUserToken } = useUser()
  const { purgeUnits } = useUnits()

  useEffect(() => {
    getCompanies()
      .then(entries => {
        setCompanies(entries)
      })
  }, [])


  const handleSelectCompany = async companyId => {
    const { jwt } = await switchCompany(companyId)

    setUserToken(jwt)
    setUser(null)

    purgeUnits()

    setShouldRedirect(true)
  }


  if (shouldRedirect) {
    return (<Redirect to="/" />)
  }


  return (
    <div className="mt-5 container">
      <div className="row justify-content-center pb-3">
        <div className="col-11 col-md-6 col-lg-8">

          <h2 className="text-center mb-5">Switch to Company</h2>
          {
            !companies ?
              <Loader fullWidth />
              :
              <CompaniesComponent companies={companies} onSelectCompany={handleSelectCompany} />
          }

        </div>
      </div>
    </div>
  )
}
