import React from 'react'

import ImageIcon from '@material-ui/icons/Image'


const getStyles = (width, height) => ({
  root: {
    height,
    position: 'relative',
    width
  },
  placeholderIcon: {
    fontSize: '4rem',
    left: 0,
    opacity: .2,
    right: 0
  },
  image: {
    height,
    objectFit: 'cover',
    position: 'absolute',
    width
  }
})


export const ImagePreview = ({ src, width, height, className = '' }) => {
  const styles = getStyles(width, height)

  return (
    <div className={`bg-light d-flex overflow-hidden ${className}`} style={styles.root}>
      {src ? (
        <img
          src={src}
          style={styles.image}
          loading="lazy"
          alt=''
        />
      ) : (
        <ImageIcon className="align-self-center mx-auto" style={styles.placeholderIcon} />
      )}
    </div>
  )
}
