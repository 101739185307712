import { Link } from 'react-router-dom'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'

import AddIcon from '@material-ui/icons/Add'
import FilterListIcon from '@material-ui/icons/FilterList'
import ReplayIcon from '@material-ui/icons/Replay'
import SortIcon from '@material-ui/icons/Sort'

import { Button } from '../ui-blocks'

import { filterOptions, sortingOptions } from './helpers'


export const Toolbar = ({
  canPromote,
  handleBulkPromotion,
  isLoading,
  loadUnits,
  selectedUnits,
  handleFilterChange,
  filterOptionIndex,
  handleSortingChange,
  sortingOptionIndex
}) => (
  <div className="bg-light position-sticky toolbar">
    <div className="container-fluid mx-auto">
      <div className="d-flex align-items-start align-items-md-center justify-content-between mt-2 mb-3 pb-2">
        <div className="d-flex flex-column flex-md-row align-items-md-center">
          <h2 className="me-5 mb-3 mb-md-0 fs-4 text-uppercase">
            Company Units
          </h2>
          <div className="d-flex align-items-center">
            <Link to="/publish-product" className="me-1">
              <Button variant="primary" className="d-flex align-items-center">
                <AddIcon className="me-1 fs-6" />
                Add Unit
              </Button>
            </Link>
            <Button variant={isLoading ? 'warning' : 'link'} onClick={loadUnits} disabled={isLoading}>
              <ReplayIcon />
            </Button>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row align-items-md-center gap-3">
          {!!selectedUnits.length && (
            <div className="pe-3 border-end d-flex gap-1 align-items-center">
              <span className="me-2">
                {selectedUnits.length} selected Unit{(selectedUnits.length === 1) ? '' : 's'}
              </span>
              {canPromote ?
                <Button variant="success" onClick={handleBulkPromotion}>
                  <span>Boost</span>
                </Button>
                : (
                  <a href={process.env.REACT_APP_BOOST_UNIT_URL} className="btn btn-success" target="_blank" rel="noreferrer">
                    <span>Boost</span>
                  </a>
                )
              }
            </div>
          )}

          <div>
            <FilterListIcon className="me-1" />
            <Select
              value={filterOptionIndex}
              onChange={handleFilterChange}
              MenuProps={{ disableScrollLock: true }}
            >
              {filterOptions.map((option, index) =>
                <MenuItem key={index} value={index}>
                  <Typography>{option.label}</Typography>
                </MenuItem>
              )}
            </Select>
          </div>

          <div>
            <SortIcon className="me-1" />
            <Select
              value={sortingOptionIndex}
              onChange={handleSortingChange}
              MenuProps={{ disableScrollLock: true }}
            >
              {sortingOptions.map((option, index) =>
                <MenuItem key={index} value={index}>
                  <Typography>{option.label}</Typography>
                </MenuItem>
              )}
            </Select>
          </div>
        </div>
      </div>
    </div>
  </div>
)
