import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Alert from '@material-ui/lab/Alert'

import { Carousel } from '../components'
import { AppLogo, LegalLinks } from '../components/ui-blocks'
import { LoginForm } from '../components/Auth'
import { loginFields } from '../components/Auth/auth-forms'

import { PERMISSIONS } from '../config/permissions'
import { useUser } from '../contexts/user'
import { login, useForm } from '../helpers'


const slides = [
  {
    imageUrl: 'slide-1-google-shopping.jpg',
    title: 'List your equipment',
    text: 'Place your equipment on multiple networks with 1 click'
  },
  {
    imageUrl: 'slide-2.jpg',
    title: 'Equipment based product advertising. Finally!',
    text: 'Your products end up on Google, allowing you to attract a retail market.'
  }
]


export const Login = () => {

  const [form, , validateForm] = useForm(loginFields)
  const { userToken, setUserToken, hasPermissions } = useUser()

  const [error, setError] = useState(false)
  const [isLoading, setLoading] = useState(false)


  const submit = async () => {
    const isFormValid = validateForm()

    if (!isFormValid) {
      return
    }

    setLoading(true)


    try {
      const response = await login(form.values)

      setUserToken(response.data.jwt)

      return
    } catch (err) {
      setError(true)
      setLoading(false)
    }
  }


  if (userToken) {
    return hasPermissions([PERMISSIONS.ADMIN]) ? (<Redirect to="/companies" />) : (<Redirect to="/" />)
  }


  return (
    <Grid container style={{ height: '100%' }}>

      <Grid item xs={12} sm={6}>
        <div className="d-flex flex-column justify-content-between full-height">

          <Box mt={{ xs: 4, sm: 15 }} p={2}>
            <Typography variant="h4" align="center">
              Welcome to Medvertise
            </Typography>

            <Box mt={5} mx="auto" style={{ maxWidth: '400px' }}>
              {error && (
                <Box mb={2}>
                  <Alert severity="error">
                    Invalid credentials
                  </Alert>
                </Box>
              )}

              {!!Object.keys(form.fields).length && (
                <LoginForm
                  isFormValid={form.isValid}
                  onSubmit={submit}
                  fields={form.fields}
                  isLoading={isLoading}
                />
              )}

              <p className="mt-4 mt-lg-5 mb-3 mb-lg-5">
                <Link to="/login/forgot-password">
                  Forgot your password?
                </Link>
              </p>
            </Box>
          </Box>
          <Box px={2} pb={0}>
            <Box mx="auto" style={{ maxWidth: '400px' }}>
              <div className="pb-4 small w-100" style={{ maxWidth: '400px' }} >
                <LegalLinks />
              </div>
            </Box>
          </Box>
        </div>
      </Grid>


      <Grid item xs={12} sm={6}>
        <div className="full-height">
          <div className="full-height bg-gradient-multicolor rounded-3 text-center">
            <Box display="flex" flexDirection="column" height="100%">
              <Box my={{ xs: 4, sm: 12 }}>
                <AppLogo />
              </Box>
              <Box alignSelf="center" flexGrow={1} width="100%">
                <Carousel slides={slides} />
              </Box>
            </Box>
          </div>
        </div>
      </Grid>

    </Grid>
  )
}
