import React from 'react'

import { StyleButton } from './StyleButton'

import { blockTypes } from './buttons'


export const BlockStyleControls = ({ editorState, onToggle }) => {

  const selection = editorState.getSelection()

  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()


  return (
    <div>
      {blockTypes.map(type =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={onToggle}
          style={type.style}
          icon={type.icon}
        />
      )}
    </div>
  )
}
