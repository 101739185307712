export const stripHtml = text =>
  String(text)
    .replace(/<[^>]+>/g, '')


/**
 * @param {*} value
 * @param {Object[]} options
 * @param {*} options[].value
 * @param {string} options[].label
 * @return {string | undefined}
 */
export const getLabelFromValue = (value, options) => options.find(option => option.value === value)?.label


export const getFormattedNumber = value => Number(value).toLocaleString()
