import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import MenuIcon from '@material-ui/icons/Menu'

import { PERMISSIONS } from '../../config/permissions'

import { Dropdown } from '../ui-blocks'


export function StaticTopBar({ user, hasPermissions, logout }) {

  const toggleButton = !!user ? (
    <span className="d-flex align-items-center pe-1">
      {!!user.companyIconUrl && (
        <img
          src={user.companyIconUrl}
          alt={user.companyName}
          className="navbar-user-icon me-2"
        />
      )}
      <span className="navbar-user-info d-none d-sm-inline-block pe-1">
        <span>
          {user.companyName}
        </span>
        <br />
        <small>
          {user.email}
        </small>
      </span>
      <MenuIcon className="d-sm-none" />
    </span>
  ) : (<MenuIcon className="pe-1" />)


  return (
    <nav className="navbar navbar-expand navbar-light">
      <Link to='/' className="navbar-brand">
        <img src={`${process.env.PUBLIC_URL}/medvertise-logo.png`} alt="Medvertise Logo" />
      </Link>
      <div className="navbar-nav ms-auto">
        {!!user && (
          <span className="d-flex align-self-center lh-sm me-4">
            {(Number(user?.balance) > 0) ? (
              <span className="text-center">
                <small>Credits</small><br />
                {user.balance}
              </span>
            ) : (
              <a href={process.env.REACT_APP_BOOST_UNIT_URL} className="text-center text-decoration-none" target="_blank" rel="noreferrer">
                <small>Credits</small><br />
                0
              </a>
            )}
          </span>
        )}

        <Dropdown
          trigger={toggleButton}
          closeOnClick={true}
          togglerClassName="btn btn-outline-dark d-flex align-items-center dropdown-toggle"
          dropdownContentClassName="end-0 w-100"
        >
          {!!user && (
            <>
              {hasPermissions([PERMISSIONS.ADMIN]) && (<>
                <Link className="dropdown-item" to="/companies/">
                  Switch Company
                </Link>
                <hr className="dropdown-divider" />
              </>)}

              <a className="dropdown-item" href={user.companyUrl} target="_blank" rel="noreferrer">
                View Seller Page
              </a>

              {!hasPermissions([PERMISSIONS.ADMIN]) &&
                <Link className="dropdown-item" to="/profile/">
                  User Settings
                </Link>
              }
              <hr className="dropdown-divider" />
            </>
          )}
          <button className="dropdown-item" onClick={logout}>Logout</button>
        </Dropdown>
      </div>
    </nav >
  )
}


StaticTopBar.propTypes = {
  user: PropTypes.object,
  hasPermissions: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
}
