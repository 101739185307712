import React from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'

import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import ImageIcon from '@material-ui/icons/Image'

import { makeStyles } from '@material-ui/core/styles'

const maxFileSizeInMegabytes = 4


const useStyles = makeStyles(theme => ({
  selectedFiles: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(.5, 0, 1)
  },
  dropArea: {
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    border: '1px dashed rgba(0, 0, 0, 0.42)',
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    padding: theme.spacing(1),
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.13)'
    }
  }
}))


export const DummyImageSelector = ({ acceptedFiles, rootProps, inputProps }) => {
  const classes = useStyles()

  const selectedFiles = acceptedFiles.map((file, i) => (
    <div className={classes.selectedFiles} key={i} >
      <ImageIcon />
      <Tooltip disableFocusListener title={file.path}>
        <Typography variant="body1" noWrap>
          {file.path}
        </Typography>
      </Tooltip>
    </div>
  ))


  const instrucctionsLabel = acceptedFiles.length === 0 ?
    'Drag \'n\' drop an image here, or click to select a file' :
    'Drag \'n\' drop an image here or click to replace'


  return (
    <section>
      {selectedFiles}
      <div className={classes.dropArea} {...rootProps}>
        <input {...inputProps} />
        <Typography variant="body2">
          {instrucctionsLabel}
        </Typography>
      </div>
    </section>
  )
}


export const ImageSelector = ({ setMedia, notifyInvalidFile = () => { } }) => {

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxSize: maxFileSizeInMegabytes * 1000000,
    multiple: false,
    onDrop: (selectedFiles, rejectedFiles) => {
      rejectedFiles.forEach(file => {
        file.errors.forEach(err => {
          if (err.code === 'file-too-large') {
            notifyInvalidFile(`The selected file is bigger than the maximum size allowed (${maxFileSizeInMegabytes} MB)`)
            return
          }

          notifyInvalidFile(err.message)
        })
      })

      const mediaElements = selectedFiles.map(file => ({
        type: 'upload-image',
        payload: file,
        previewUrl: URL.createObjectURL(file)
      }))

      setMedia(mediaElements.length ? mediaElements[0] : null)
    }
  })


  return (
    <DummyImageSelector
    rootProps={getRootProps()}
    inputProps={getInputProps()}
    acceptedFiles={acceptedFiles}
  />
  )
}


ImageSelector.propTypes = {
  setMedia: PropTypes.func.isRequired,
  notifyInvalidFile: PropTypes.func
}
