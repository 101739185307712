import React from 'react'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'

import { ImagePreview } from '..'


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.divider,
    color: 'rgb(51, 51, 51)'
  },
  title: {
    fontWeight: 'normal',
    lineHeight: '26px'
  },
  price: {
    color: 'black',
    fontSize: '18px'
  }
}))


export const GoogleShoppingSnippet = ({ product }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root} p={1} border={1} display="flex" alignItems="center">
      <Box mr={2}>
        <ImagePreview
          src={product.media?.previewUrl}
          width={180}
          height={180}
        />
      </Box>
      <Box flexGrow={1}>
        <Typography variant="h6" className={classes.title}>
          {product.year} {product.product.title || (<span className="snippet-field-placeholder">Model Name</span>)}
          {product.title && ` ${product.title}`}
        </Typography>
        <Typography variant="subtitle1" className={classes.price}>
          <strong>
            {Number(product.price) ?
              <span>${Number(product.price).toLocaleString()}</span>
              : <span className="snippet-field-placeholder">Insert Price</span>
            }
          </strong>
          {product.condition ?
            <span> {(['new', 'parts'].includes(product.condition)) ? '' : product.condition}</span>
            : <span className="snippet-field-placeholder"> - Select Product Condition</span>
          }
        </Typography>
        <Typography variant="body2">
          {product.productDescription ?
            <span>{product.productDescription.substring(0, 170)}</span>
            : <span className="snippet-field-placeholder">Insert Description</span>
          }
        </Typography>
      </Box>
    </Box>
  )
}
