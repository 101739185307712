export const hasNewProductTerms = product => {
    if (product.id) {
        return true
    }


    return [
        'manufacturerId',
        'categoryId',
        'typeId'
    ].every(key => !!product[key])

}
