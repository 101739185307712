import React from 'react'

import { ImagePreview } from '..'


export const MiniSnippet = ({ product }) => (
  <div className="d-flex shadow-sm border rounded">
    <div>
      <ImagePreview
        src={product.media?.previewUrl}
        width={100}
        height={100}
        className="rounded-start"
      />
    </div>
    <div className="py-1 px-2">
      <h6>
        {`${product.product.title} ${product.title}`}
      </h6>
      <p className="fs-6 lh-1">
        <strong>
          <span>${Number(product.price).toLocaleString()}</span>
        </strong>
        <span> - {product.condition}</span>
      </p>
    </div>
  </div>
)
