import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { GoogleShoppingSnippet } from '../Product'

import { SubmitButton } from '../ui-blocks'

import { getPriceRange } from './helpers'
import { MiniSnippet } from './MiniSnippet'


export const PromoteDeviceForm = ({ units, isLoading, balance, onPromoteDevice }) => {

    const [credits, setCredits] = useState(1)

    if (!Array.isArray(units) || units.length < 1) {
        return null
    }


    const { min, max, priceRangeSteps } = getPriceRange(units.length)

    const handleBudgetChange = event => setCredits(Math.min(parseInt(event.target.value, 10), balance))

    const handlePromoteDevice = () => onPromoteDevice(units.map(unit => unit.id), { budget: credits })


    const Snippet = (units.length < 2) ? GoogleShoppingSnippet : MiniSnippet
    const snippetClassName = (units.length < 4) ? 'g-col-12' : 'g-col-6'

    return (<>
        <div className="grid gap-3 text-start mb-5">
            {units.map(unit => (
                <div key={unit.id} className={`${snippetClassName}`}>
                    <Snippet product={unit} />
                </div>
            ))}
        </div>

        <div className="mx-5 mb-3">
            <label htmlFor="credits" className="form-label">Advertising Credits</label>

            <input
                type="range"
                id="credits"
                className="form-range"
                min={min} max={max} step="1"
                value={credits}
                onChange={handleBudgetChange}
            />
            <div className="d-flex justify-content-between">
                {priceRangeSteps.map(price => (
                    <span key={price} className={`daily-credits ${(price > balance) ? 'text-muted' : 'fw-bold'}`}>{price}</span>
                ))}
            </div>
        </div>

        <p className="fs-5">
            {credits} x 30 days = <strong>{credits}</strong> Credit{(credits === 1) ? '' : 's'}<br />
            Estimated Leads = <strong>{credits * 2.5}</strong>
        </p>

        <div className="d-flex justify-content-center">
            <SubmitButton isLoading={isLoading} onClick={handlePromoteDevice}>
                Boost Unit
            </SubmitButton>
        </div>
    </>)
}


PromoteDeviceForm.propTypes = {
    units: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool.isRequired,
    balance: PropTypes.number.isRequired,
    onPromoteDevice: PropTypes.func.isRequired
}
