import React from 'react'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PauseIcon from '@material-ui/icons/Pause'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import VisibilityIcon from '@material-ui/icons/Visibility'

import { getFormattedNumber } from '../../../helpers'
import { Badge } from '../..'


export const GooglePromotionStatus = ({ adStatus }) => {

  const variant = adStatus?.isPromoted ? 'success' : 'secondary'
  const Icon = adStatus?.isPromoted ? CheckCircleIcon : PauseIcon

  const attr = adStatus.attributes


  return (<>
    <Badge variant={variant}>
      <span className="d-flex align-items-center">
        <Icon style={{ fontSize: '1.2em' }} className="me-1" />
        Promoted on Google
      </span>
    </Badge>

    <div className="small">
      <span className="text-muted">
        {(attr.servingStatus === 'ENDED') ? 'Ended' : 'Renews'}:
      </span> {attr.endDate}
    </div>

    <div className="row g-1 small">
      <div className="col">
        <span className="text-muted">
          <VisibilityIcon style={{ fontSize: '1.2em' }} className="me-1" />
        </span>{getFormattedNumber(attr.impressions)}
      </div>
      <div className="col">
        <span className="text-muted">
          <TrendingUpIcon style={{ fontSize: '1.2em' }} className="me-1" />
        </span>{getFormattedNumber(attr.clicks)}
      </div>
    </div>
  </>)
}
