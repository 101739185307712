import React, { useState, useRef, } from 'react'
import PropTypes from 'prop-types'

import { useOutsideClick } from '../outside-click'


export const Dropdown = ({
  trigger,
  closeOnClick = false,
  className = '',
  togglerClassName = '',
  dropdownContentClassName = '',
  children
}) => {
  const [expanded, setExpanded] = useState(false)
  const wrapperRef = useRef(null)

  useOutsideClick(wrapperRef, () => setExpanded(false))

  const toggleDisplay = () => {
    setExpanded(!expanded)
  }

  const handleContentClick = () => {
    if (closeOnClick) {
      setExpanded(false)
    }
  }


  const handleKeyPress = e => {
    if (e.keyCode === 27) { // Escape Key
      e.preventDefault()

      wrapperRef.current.blur()
      setExpanded(false)
    }
  }


  return (
    <div className={`dropdown ${className}`} ref={wrapperRef} onKeyDown={handleKeyPress}>
      <div
        onClick={toggleDisplay}
        className={`${togglerClassName} ${expanded ? 'show' : 'collapsed'}`}
      >
        {trigger}
      </div>
      <div
        onClick={handleContentClick}
        className={`dropdown-menu ${dropdownContentClassName} ${expanded ? 'show' : ''}`}
      >
        {children}
      </div>
    </div>
  )
}


Dropdown.propTypes = {
  trigger: PropTypes.node.isRequired,
  closeOnClick: PropTypes.bool
}
