import React, { useLayoutEffect } from 'react'

import { useUser } from '../../contexts/user'
import { getCompany } from '../../helpers'

import { StaticTopBar } from './StaticTopBar'


export function TopBar() {
  const { user, setUser, hasPermissions, logoutUser } = useUser()


  useLayoutEffect(() => {
    if (!user) {
      getCompany()
        .then(avatar => {
          setUser(avatar)
        })
    }
  })


  return (
    <header className="fixed-top bg-light">
      <div className="container-fluid">
        <StaticTopBar user={user} hasPermissions={hasPermissions} logout={logoutUser} />
      </div>
    </header>
  )
}
