import { InputField } from './InputField'


export function getInitializedFields(fieldOptions) {
    const fields = {}

    Object.keys(fieldOptions).forEach(key => {
        if (typeof fieldOptions[key] === 'function') {
            return
        }

        fields[key] = new InputField({
            name: key,
            ...fieldOptions[key]
        })
    })

    if (typeof fieldOptions.__setFields === 'function') {
        fieldOptions.__setFields(fields)
    }

    return fields
}
