import React from 'react'

import TextField from '@material-ui/core/TextField'

import { FullWidthSubmitButton } from '..'


const commonProps = {
  fullWidth: true,
  variant: 'filled',
  InputLabelProps: {
    shrink: true,
  },
}


export const LoginForm = ({ fields, isFormValid, isLoading, onSubmit }) => (
  <form onSubmit={e => {
    e.preventDefault()
    onSubmit()
  }}>
    <TextField
      {...commonProps}
      label="Email *"
      error={!fields.email.isValid}
      autoComplete="email"
      name="email"
      type="email"
      className="mb-4"
      {...fields.email.getProps()}
      />

    <TextField
      {...commonProps}
      label="Password *"
      error={!fields.password.isValid}
      type="password"
      autoComplete="current-password"
      name="password"
      className="mb-4"
      {...fields.password.getProps()}
    />

    <FullWidthSubmitButton
      isLoading={isLoading}
      enabled={isFormValid}
      type="submit"
    >
      Login
      </FullWidthSubmitButton>
  </form>
)
