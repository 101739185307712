import { useEffect, useReducer } from 'react'

import { getInitializedFields } from './model'


function getValues(fields) {
  const values = {}

  Object.keys(fields).forEach(key => {
    values[key] = fields[key].value
  })

  return values
}


function validateFields(fields) {
  let isValid = true

  Object.keys(fields).forEach(key => {
    fields[key].validate()
    isValid = isValid && fields[key].isValid
  })

  return isValid
}


function reducer(state, action) {
  switch (action.type) {
    case 'setInitial':
      const { fieldOptions, handleChange } = action.payload

      const fields = getInitializedFields(fieldOptions)

      Object.keys(fields).forEach(key => {
        fields[key].onChange = handleChange
      })

      return {
        ...state,
        fields,
        values: getValues(fields)
      }

    case 'setValues':
      Object.keys(state.fields).forEach(key => {
        if (typeof action.payload[key] !== 'undefined') {
          state.fields[key].value = action.payload[key]
        }
      })

      const values = getValues(state.fields)

      return {
        ...state,
        values,
        initialValues: values,
        isValid: validateFields(state.fields)
      }

    case 'refresh':
      return {
        ...state,
        values: getValues(state.fields),
        isValid: validateFields(state.fields)
      }


    case 'setValidity':
      return {
        ...state,
        isValid: action.payload
      }

    default:
      throw new Error()
  }
}


export const useForm = fieldOptions => {

  const [form, dispatch] = useReducer(reducer, {
    fields: {},
    values: {},
    initialValues: {},
    isValid: true
  })

  const handleChange = () => dispatch({ type: 'refresh' })

  useEffect(() => {
    dispatch({
      type: 'setInitial', payload: {
        fieldOptions,
        handleChange
      }
    })
  }, [fieldOptions])


  return [
    form,
    values => dispatch({ type: 'setValues', payload: values }),
    () => {
      const isFormValid = validateFields(form.fields)

      dispatch({ type: 'setValidity', payload: isFormValid })

      return isFormValid
    }
  ]
}
