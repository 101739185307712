import React from 'react'

import Button from '@material-ui/core/Button'

import { Loader } from './Loader'


export const FullWidthSubmitButton = ({ children, enabled, isLoading, className = '', ...props }) => (
  <Button variant="contained" color="primary" disabled={isLoading || !enabled}
    className={`d-flex align-items-center w-100 ${className}`}
    {...props}
  >
    {isLoading && <Loader className="me-2 spinner-border-sm" />}
    {children}
  </Button>
)
