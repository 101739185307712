import React from 'react'


export const FormField = ({ label, children, validationMessage = null }) => (
  <div className="row mb-3">
    <div className="col-sm-3 pt-sm-2 mb-1 text-sm-end small lh-sm">
      {label}
    </div>
    <div className="col-sm-9">
      {children}
      {validationMessage && (
        <div className="invalid-feedback d-block">
          {validationMessage}
        </div>
      )}
    </div>
  </div>
)
