import { useEffect, useState } from 'react'

import { Notifications as NotificationsComponent } from '../components/Notifications'
import { Loader } from '../components/ui-blocks'
import { getNotifications } from '../helpers/mpo-wp-api'


export const Notifications = () => {
  const [notifications, setNotifications] = useState()

  useEffect(() => {
    getNotifications()
      .then(entries => {
        setNotifications(entries.data)
      })
  }, [])


  return (
    <div className="mt-5 pt-4">

      <div className="container-fluid mx-auto">

        <h2 className="text-center mb-3">Notifications</h2>
        {
          !notifications ?
            <Loader fullWidth />
            :
            <NotificationsComponent notifications={notifications.products} />
        }

      </div>
    </div>
  )
}
