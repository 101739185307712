import React from 'react'
import { Link } from 'react-router-dom'

import Alert from '@material-ui/lab/Alert'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import { FullWidthSubmitButton } from '..'


export const SetPasswordForm = ({
  fields,
  isValid,
  isLoading,
  hasSuccess,
  errors,
  onSubmit
}) => {

  const arePasswordEquals = !!Object.keys(fields).length && (fields.password1.value === fields.password2.value)

  if (hasSuccess) {
    return ((
      <div>
        <Alert severity="success" className="mb-3">
          Your password was updated. You can login now.
        </Alert>
        <div className="text-center">
          <Link to="/">
            <Button variant="contained" color="primary">Log in</Button>
          </Link>
        </div>
      </div >
    ))
  }


  return (<>
    {!!errors.length && (<>
      {errors.map((errorMessage, index) => (
        <Alert key={index} severity="error" className="mt-3">
          {errorMessage}
        </Alert>
      ))}
    </>
    )}

    {!!Object.keys(fields).length && (

      <form onSubmit={e => {
        e.preventDefault()
        onSubmit()
      }}>
        <TextField
          label="New Password *"
          error={!fields.password1.isValid}
          autoComplete="new-password"
          id="new-password"
          type="password"
          className="mb-4"
          fullWidth
          variant="filled"
          InputLabelProps={{
            shrink: true
          }}
          {...fields.password1.getProps()}
        />

        <TextField
          label="Repeat New Password *"
          error={!fields.password2.isValid || !arePasswordEquals}
          autoComplete="off"
          id="password-repeat"
          type="password"
          className="mb-4"
          fullWidth
          variant="filled"
          InputLabelProps={{
            shrink: true
          }}
          {...fields.password2.getProps()}
        />

        {(!!fields.password2.value && !arePasswordEquals) && (
          <Alert severity="error" className="mb-3">
            Please introduce the same password on both fields.
          </Alert>
        )}

        <FullWidthSubmitButton
          isLoading={isLoading}
          enabled={isValid && arePasswordEquals}
          type="submit"
        >
          Set New Password
        </FullWidthSubmitButton>
      </form>

    )}
  </>)
}
