import React from 'react'


export const Badge = ({ variant = 'primary', className = '', children, ...props }) => (
  <span
    className={`badge border border-${variant} border-2 rounded-pill bg-white`
      + ` text-${variant} ${className}`}
    {...props}
  >
    {children}
  </span>
)
