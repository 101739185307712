import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import OpenInNewIcon from '@material-ui/icons/OpenInNew'


import { getDate } from '../../helpers'

import { Badge, Button, CheckboxField, ImagePreview } from '..'

import { GoogleShoppingStatus, Promotions } from './components'
import { getLabelByKey, modalityOptions } from '../Product/product-fields'


const Field = ({ label, value, className = '' }) => (
  <div className={className}>
    <span className="text-muted">
      {label}:&thinsp;
    </span>
    {value}
  </div>
)


/**
 * @param {Array} promotions
 */
const hasSinglePromotion = promotions =>
  !!promotions.find(promotion => promotion.attributes.itemCount === 1)


export const UnitSnippet = ({ unit, onPromoteDevice, isSelected = false, onToggleSelection }) => {
  const editUnitUrl = `/unit/${unit.id}`

  const isUnitPromotable = unit.adStatus.isShoppingApproved
    && !unit.skipPublishing

  const canUserPromote = (onPromoteDevice instanceof Function)


  const isPending = (unit.status === 'pending')
  const isRental = (unit.modality === 'rent')
  const modality = String(getLabelByKey(unit.modality, modalityOptions))

  const cardClassName = isPending ? 'bg-light' : 'bg-white'
  const opacityClassName = isPending ? 'opacity-50' : ''

  return (
    <div className={`card rounded shadow-sm ${cardClassName}`}>
      <div className="row g-0">

        <div className={`col-md-2 d-none d-md-block ${opacityClassName}`}>
          <Link to={editUnitUrl}>
            <ImagePreview
              src={unit.media?.previewUrl}
              width="100%"
              height="100%"
              className="card-image rounded-start"
            />
          </Link>
        </div>

        <div className="col-md-10">
          <div className="card-body">
            <div className="d-flex d-md-block justify-content-between mb-3">
              <div className="d-flex justify-content-between flex-column flex-lg-row">
                <div className="flex-grow-1">
                  <div className="d-md-flex justify-content-between">
                    <h2 className="h4 fw-normal mb-0">
                      <Link to={editUnitUrl} className="link-dark text-decoration-none">
                        {unit.title || (<span className="text-muted">Untitled Unit</span>)}
                      </Link>
                    </h2>

                    <div className="d-flex flex-md-row-reverse align-items-center gap-3">
                      {(isUnitPromotable && onToggleSelection) &&
                        <CheckboxField
                          value={isSelected}
                          setValue={() => onToggleSelection(unit)}
                          label=""
                          className="me-n2"
                        />
                      }

                      {isPending ? (
                        <span>
                          <Badge variant="warning" className="bg-dark">Pending Approval</Badge>
                        </span>
                      ) : (
                        <a
                          href={unit.url}
                          className="btn btn-primary btn-sm"
                          target="_blank"
                          rel="noreferrer"
                        >
                          View
                          <OpenInNewIcon style={{ fontSize: '1em' }} className="ms-1" />
                        </a>
                      )}

                      <GoogleShoppingStatus
                        skipPublishing={unit.skipPublishing}
                        isShoppingApproved={unit.adStatus?.isShoppingApproved}
                        isAvailableOnGoogleShopping={unit.isAvailableOnGoogleShopping}
                      />

                      <Badge variant="muted" className="bg-dark">{modality}</Badge>
                    </div>

                  </div>
                  <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between">

                    <div>
                      {!unit.productDescription && (
                        <Badge variant="danger">Missing Description</Badge>
                      )}
                    </div>

                    <div className="d-flex flex-column flex-md-row">
                      {(unit.publishDate > 0) &&
                        <Field label="Published" value={getDate(unit.publishDate)} />
                      }
                      {((unit.updatedDate > 0) && (unit.publishDate !== unit.updatedDate)) &&
                        <Field label="Updated" value={getDate(unit.updatedDate)} className="ms-md-3" />
                      }
                    </div>
                  </div>
                </div>
              </div>
              {(unit.media?.type === 'image') && (
                <div className={`d-md-none ${opacityClassName}`}>
                  <Link to={editUnitUrl}>
                    <ImagePreview
                      src={unit.media?.previewUrl}
                      width={120}
                      height={120}
                      className="card-image"
                    />
                  </Link>
                </div>
              )}
            </div>

            <div className={`row g-2 ${opacityClassName}`}>

              <div className="col-md-8 col-lg-4">
                <div className="d-flex align-items-center mb-1">
                  <h6 className="mb-0 fw-bold">
                    Product
                  </h6>
                  {(!unit.product.isValid && !unit.product.isPending) && (
                    <Badge variant="danger" className="ms-2">Invalid Product</Badge>
                  )}
                </div>
                <div className="lh-sm mb-1 fw-bold">{unit.product.title}</div>

                {unit.product.categoryName && (
                  <Field label="Category" value={unit.product.categoryName} className="small lh-sm mb-1" />
                )}
                {unit.product.typeName && (
                  <Field label="Type" value={unit.product.typeName} className="small lh-sm" />
                )}
              </div>

              <div className="col-sm-6 col-md-4 col-lg-3">
                {unit.shippingLocation && (Number(unit.shippingTimeframe) > 0) && (
                  <>
                    <h6 className="mb-1 fw-bold">
                      {isRental ? 'Rental Details' : 'Shipping'}
                    </h6>
                    {unit.shippingLocation &&
                      <Field
                        label={isRental ? 'Service Region' : 'Location'}
                        value={unit.shippingLocation}
                        className="lh-sm mb-1" />
                    }
                    {!isRental && (
                      <>
                        {!!Number(unit.shippingTimeframe) &&
                          <Field
                            label="Timeframe"
                            value={`${Number(unit.shippingTimeframe).toLocaleString()} days`}
                            className="lh-sm mb-1" />
                        }
                        {!!Number(unit.shippingFee) ?
                          <Field label="Fee"
                            value={`$ ${Number(unit.shippingFee).toLocaleString()}`}
                            className="lh-sm" />
                          :
                          <h6 className="fw-normal">Free Shipping</h6>
                        }
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="col-md-4 col-lg-2">
                <h6 className="mb-0 fw-bold">
                  Price
                </h6>
                <h4 className="mb-0">
                  $ {Number(unit.price).toLocaleString()}
                </h4>
                {isRental && (
                  <Badge variant="warning" className="mb-1">Monthly Rental</Badge>
                )}
                <Field
                  label="Year"
                  value={(!!unit.year) ? unit.year : 'Undefined'}
                  className="small lh-sm mb-1"
                />
              </div>

              {!isPending && (unit.hasOwnershipPermission !== false) && (
                <div className="col-md-8 col-lg-3">
                  {
                    (canUserPromote ? (
                      (isUnitPromotable && !hasSinglePromotion(unit.adStatus.campaigns)) &&
                      <Button variant="success" onClick={() => onPromoteDevice([unit])} className="mb-3">
                        Boost Unit
                      </Button>
                    ) : (
                      <a className="btn btn-success mb-3" href={process.env.REACT_APP_BOOST_UNIT_URL} target="_blank" rel="noreferrer">
                        Boost Unit
                      </a>
                    )
                    )
                  }

                  <Promotions campaigns={unit.adStatus.campaigns} />
                </div>
              )}

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}


UnitSnippet.propTypes = {
  unit: PropTypes.object.isRequired,
  promoteDevice: PropTypes.func,
  isSelected: PropTypes.bool,
  onToggleSelection: PropTypes.func
}
