import PropTypes from 'prop-types'


import { UnitSnippet } from './UnitSnippet'


export const Units = ({ units, selectedUnits, setSelectedUnits, handlePromoteDevice }) => {

  const selectedUnitIds = selectedUnits.map(selectedUnit => selectedUnit.id)


  const handleToggleSelectedUnit = unit => {
    if (selectedUnitIds.includes(unit.id)) {
      setSelectedUnits(selectedUnits.filter(selectedUnit => selectedUnit.id !== unit.id))
      return
    }

    setSelectedUnits([...selectedUnits, unit])
  }


  return (<>

    {units.map(unit => (
      <div key={unit.id} className="mb-3">
        <UnitSnippet
          unit={unit}
          onPromoteDevice={handlePromoteDevice}
          isSelected={selectedUnitIds.includes(unit.id)}
          onToggleSelection={handleToggleSelectedUnit}
        />
      </div>
    ))}

  </>)
}


Units.propTypes = {
  units: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedUnits: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedUnits: PropTypes.func.isRequired,
  handlePromoteDevice: PropTypes.func
}
