import React from 'react'

import DeleteIcon from '@material-ui/icons/Delete'


export const getAlert = body => ({
  body,
  buttons: [
    {
      label: 'Ok',
      action: true,
      variant: 'primary'
    }
  ]
})


export const getConfirmModal = ({ body, confirmProps }) => ({
  body,
  buttons: [
    {
      ...confirmProps,
      action: true
    },
    {
      label: 'Cancel',
      action: false
    }
  ]
})


export const getConfirmDelete = body =>
  getConfirmModal({
    body,
    confirmProps: {
      label: (<><DeleteIcon style={{ fontSize: '1.2em' }} className="me-1" />Delete</>),
      variant: 'danger'
    }
  })
