import React from 'react'
import PropTypes from 'prop-types'

import { Button } from './Button'
import { Loader } from './Loader'


const loaderSize = {
    height: '1rem',
    width: '1rem'
}


const IconPlaceholder = (<span className="d-inline-block" style={loaderSize}></span>)


export const SubmitButton = ({
    isLoading = false,
    enabled = true,
    className = '',
    children,
    ...props
}) => {

    let mainIcon = IconPlaceholder

    if (isLoading) {
        mainIcon = (<Loader style={loaderSize} variant="white" />)
    }


    return (
        <Button
            disabled={isLoading || !enabled}
            className={`px-3 ${className}`}
            {...props}>
            <>
                {mainIcon}
                <span className="mx-2">{children}</span>
                {IconPlaceholder}
            </>
        </Button>
    )
}

SubmitButton.propTypes = {
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    enabled: PropTypes.bool
}
