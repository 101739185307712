/**
 * @param {number} timestamp - Unix Timestamp with precision of seconds
 */
export const getDate = timestamp =>
  new Date(timestamp * 1000)
    .toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    })


/**
 * @param {number} timestamp - Unix Timestamp with precision of seconds
 */
export const getDateTime = timestamp =>
  new Date(timestamp * 1000)
    .toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    })
