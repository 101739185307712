import {
  isRequired,
  isPositiveNumber,
  isBetweenNumbers,
  isValidBasicUrl,
  maxLength
} from '../../helpers/Form'


const yearValidator = isBetweenNumbers(1980, new Date().getFullYear())

export const formFields = {
  product: {
    validators: [
      value => !!value.title
    ],
    value: {
      id: null,
      title: null
    }
  },
  title: {
    maxLength: 60,
    validators: [maxLength(60)],
    description: 'List key accessories or top features that dictate price'
  },
  productDescription: {
    validators: [isRequired]
  },
  condition: {
    validators: [isRequired]
  },
  modality: {
    validators: [isRequired],
    value: 'sale'
  },
  price: {
    validators: [isRequired, isPositiveNumber]
  },
  year: {
    validators: [yearValidator]
  },
  media: {
    value: null
  },
  shippingLocation: {},
  shippingTimeframe: {
    validators: [isPositiveNumber]
  },
  shippingFee: {
    validators: [shippingFeeValidator, isBetweenNumbers(0, 1000)]
  },
  serviceRegion: {},
  bidListingUrl: {},
  skipPublishing: {
    value: false
  },
  __setFields: fields => {
    fields.bidListingUrl.validators = [
      value => (fields.modality.value !== 'auction') ? true :
        isRequired(value) && isValidBasicUrl(value)
    ]
  }
}


export const conditionsOptions = [
  { value: 'used', label: 'Used' },
  { value: 'refurbished', label: 'Refurbished' },
  { value: 'new', label: 'New' },
  { value: 'parts', label: 'Parts' }
]


export const modalityOptions = [
  { value: 'sale', label: 'For Sale' },
  { value: 'rent', label: 'For Rent' },
  { value: 'auction', label: 'Auction' }
]


export const getLabelByKey = (search, options) => {
  const option = options.find(({ value }) => value === search)

  return option ? option.label : null
}


function shippingFeeValidator(value) {

  if (String(value).includes('.') || String(value).includes(',')) {
    return 'Fee should not include periods (.) or commas (,).'
  }

  if (Number(value) > 1000) {
    return <span>
      Fee must be lower or equal to USD 1000 in order to be elegible for listing
      in <a
        href="https://support.google.com/merchants/answer/6324484?hl=en"
        target="_blank"
        rel="noreferrer">
        Google Shopping
      </a>
    </span>
  }

  return true
}
