import React, { useState, createContext, useContext } from 'react'


const UnitsContext = createContext()
UnitsContext.displayName = 'UnitsContext'


export const useUnits = () => {
  const {
    units,
    setUnits,
    selectedUnits,
    setSelectedUnits
  } = useContext(UnitsContext)

  return {
    units,
    setUnits,
    selectedUnits,
    setSelectedUnits,
    purgeUnits: () => {
      setUnits(null)
      setSelectedUnits([])
    }
  }
}


export const UnitsProvider = ({ children }) => {
  const [units, setUnits] = useState()
  const [selectedUnits, setSelectedUnits] = useState([])

  return (
    <UnitsContext.Provider value={{
      units,
      setUnits,
      selectedUnits,
      setSelectedUnits
    }}>
      {children}
    </UnitsContext.Provider>
  )
}
