import React, { useEffect, useState } from 'react'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import AddIcon from '@material-ui/icons/Add'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'


import { GoogleShoppingSnippet, InsertUnit as InsertUnitComponent, MedicalPriceOnlineSnippet } from '../components/Product'
import { CheckboxField } from '../components/ui-blocks'

import { useUnits, useUser } from '../contexts'
import { getParsedFormData, useForm } from '../helpers'
import { getProducts, getProductTerms, insertUnit } from '../helpers/mpo-wp-api'

import { formFields } from '../components/Product/product-fields'


export const InsertUnit = () => {

  const [form, setFormValues, validateForm] = useForm(formFields)
  const { purgeUnits } = useUnits()
  const { userCompanyName } = useUser()

  const [isLoading, setLoading] = useState(false)
  const [result, setResult] = useState(null)
  const [resetState, setResetState] = useState(1)

  const [availableProducts, setAvailableProducts] = useState([])


  function loadProducts() {
    getProducts()
      .then(results => {
        setAvailableProducts(results || [])
      })
  }


  useEffect(() => {
    loadProducts()
  }, [])


  const resetForm = () => {
    const emptyValues = {}

    Object.keys(formFields).forEach(fieldName => {
      emptyValues[fieldName] = (typeof formFields[fieldName].value !== 'undefined') ? formFields[fieldName].value : ''
    })

    setFormValues(emptyValues)
    loadProducts()
    setResetState(resetState + 1)

    setLoading(false)
    setResult(null)
  }


  const submit = async () => {
    const isFormValid = validateForm()

    if (!isFormValid) {
      return
    }

    setLoading(true)

    setResult(await insertUnit(getParsedFormData(form.values)))

    purgeUnits()

    setLoading(false)
  }


  return (
    <Box className="container-fluid" mt={8} py={3} px={2}>
      <h2 className="text-center mb-5">
        ADD TO GOOGLE &amp; MEDICALPRICEONLINE.COM WITH THE CLICK OF A BUTTON
      </h2>

      <Collapse in={!result}>
        <div className="row g-5">
          <div className="col-lg-7 col-xl-6">

            {!!Object.keys(form.fields).length && (
              <InsertUnitComponent
                isFormValid={form.isValid}
                onSubmit={submit}
                fields={form.fields}
                isLoading={isLoading}
                availableProducts={availableProducts}
                getTerms={getProductTerms}
                key={resetState}
              />
            )}

          </div>
          {!!Object.keys(form.fields).length && (
            <div className="col-lg-5 col-xl-6">
              <div className="d-flex flex-column gap-5 sticky-top sticky-stacked-top">
                <div>
                  <h5 className="ms-1">Medical Price Online Preview</h5>

                  <MedicalPriceOnlineSnippet
                    companyName={userCompanyName}
                    product={form.values}
                  />
                </div>

                <div>
                  <div className="d-flex ms-1 justify-content-between align-items-center">
                    <h5 className="mb-0">Google Shopping Preview</h5>

                    <CheckboxField
                      value={form.fields.skipPublishing.value}
                      setValue={form.fields.skipPublishing.setValue}
                      label="Don't Publish"
                    />
                  </div>

                  <GoogleShoppingSnippet product={form.values} />
                </div>
              </div>
            </div>
          )}
        </div>
      </Collapse>

      {!!result &&
        <Collapse in>
          <Grid container>
            <Grid item xs={12} sm={11} style={{ margin: '3rem auto' }}>
              <Typography variant="h4" gutterBottom>
                We submitted your product.
              </Typography>

              <Box my={3}>
                <Grid container>
                  <Grid item xs={12} md={7} style={{ margin: '1rem 0' }}>
                    {!result.product.isPending && (
                      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                        <Typography>
                          Now available on <a href={result.url} target="_blank" rel="noopener noreferrer">
                            Medical Price Online
                          </a>
                        </Typography>
                        {(result.status === 'publish') && (
                          <Button href={result.url} variant="contained" color="primary" target="_blank" rel="noopener">
                            <OpenInNewIcon style={{ fontSize: '1rem', marginRight: '.5rem' }} />
                            View your Product
                          </Button>
                        )}
                      </Box>
                    )}
                    <MedicalPriceOnlineSnippet
                      companyName={userCompanyName}
                      product={form.values}
                    />
                  </Grid>
                </Grid>
              </Box>

              <hr style={{ marginBottom: '2rem' }} />

              <Button onClick={resetForm} variant="contained" color="primary">
                <AddIcon style={{ fontSize: '1rem', marginRight: '.5rem' }} />
                Upload new Product
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      }
    </Box >
  )
}
