import React, { useState, createContext, useContext } from 'react'

import { decodeJwt, getUserTokenLocalStorage, setUserTokenLocalStorage } from '../helpers/auth'

import { createHasPermissions } from './user-helpers'


const UserContext = createContext()


export const useUser = () => {
  const userContext = useContext(UserContext)

  return {
    ...userContext
  }
}


export const UserProvider = ({ children }) => {
  const [userToken, setUserToken] = useState(decodeJwt(getUserTokenLocalStorage()))
  const [user, setUser] = useState()


  const logoutUser = () => {
    setUserTokenLocalStorage('')

    setUserToken(null)
    setUser(null)
  }


  if (userToken && (!userToken.exp || userToken.exp < Date.now() / 1000)) {
    logoutUser()
  }

  const setUserTokenProxy = token => {
    setUserTokenLocalStorage(token)

    setUserToken(decodeJwt(token))
  }


  const hasPermissions = createHasPermissions(userToken?.permissions || [])


  return (
    <UserContext.Provider value={{
      userToken,
      setUserToken: setUserTokenProxy,
      user,
      setUser,
      balance: user?.balance,
      userCompanyName: user?.companyName,
      logoutUser,
      hasPermissions
    }}>
      {children}
    </UserContext.Provider>
  )
}
