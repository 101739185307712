import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { EditorState, RichUtils, getDefaultKeyBinding } from 'draft-js'
import { stateFromHTML } from 'draft-js-import-html'
import { stateToHTML } from 'draft-js-export-html'

import Editor from '@draft-js-plugins/editor'

import { BlockStyleControls } from './BlockStyleControls'
import { InlineStyleControls } from './InlineStyleControls'

import 'draft-js/dist/Draft.css'


const styleMap = {
  'PARAGRAPH': {
    'backgroundColor': '#faed27'
  }
}


export const HtmlEditor = ({ value, onChange, className = '' }) => {

  const [editorState, setEditorState] = useState(null)
  const [shouldIgnoreUpdate, setShouldIgnoreUpdate] = useState(false)
  const editor = useRef(null)


  useEffect(() => {
    if (shouldIgnoreUpdate) {
      setShouldIgnoreUpdate(false)
      return
    }

    const state = value ? EditorState.createWithContent(stateFromHTML(value)) : EditorState.createEmpty()

    setEditorState(state)
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps


  const focusEditor = () => {
    editor.current.focus()
  }


  const handleChange = newEditorState => {
    setEditorState(newEditorState)

    if (typeof onChange === 'function') {
      setShouldIgnoreUpdate(true)
      onChange(stateToHTML(newEditorState.getCurrentContent()))
    }
  }


  const handleKeyCommand = (command, newEditorState) => {
    const newState = RichUtils.handleKeyCommand(newEditorState, command)

    if (newState) {
      handleChange(newState)
      return true
    }

    return false
  }


  const mapKeyToEditorCommand = e => {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        editorState,
        4 /* maxDepth */
      )

      if (newEditorState !== editorState) {
        handleChange(newEditorState)
      }

      return
    }

    return getDefaultKeyBinding(e)
  }


  const toggleBlockType = blockType => {
    handleChange(
      RichUtils.toggleBlockType(
        editorState,
        blockType
      )
    )

    focusEditor()
  }


  const toggleInlineStyle = inlineStyle => {
    handleChange(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle
      )
    )

    focusEditor()
  }


  return editorState && (
    <div className={className}>
      <div className="d-flex mb-2">
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />

        <div className="ms-1 ps-1">
          <BlockStyleControls
            editorState={editorState}
            onToggle={toggleBlockType}
          />
        </div>
      </div>

      <div className="border rounded-top bg-white p-2">
        <Editor
          customStyleMap={styleMap}
          blockStyleFn={getBlockStyle}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={mapKeyToEditorCommand}
          onChange={handleChange}
          ref={editor}
          spellCheck={true}
        />
      </div>
    </div>
  )

}


function getBlockStyle(block) {
  switch (block.getType()) {
    case 'unstyled':
      return 'paragraph'
    default:
      return null
  }
}


HtmlEditor.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
}
