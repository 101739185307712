import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'

import { RequestPasswordRecoveryForm } from '../../components/Auth/RequestPasswordRecoveryForm'
import { requestPasswordRecoveryFields } from '../../components/Auth/auth-forms'
import { AppLogo } from '../../components/ui-blocks'

import { useUser } from '../../contexts/user'
import { useForm } from '../../helpers'
import { requestPasswordRecovery } from '../../helpers/mpo-wp-api'


export const RequestPasswordRecovery = () => {

  const [form, setFormValues, validateForm] = useForm(requestPasswordRecoveryFields) // eslint-disable-line no-unused-vars

  const { userToken } = useUser()

  const [errors, setErrors] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [hasSuccess, setSuccess] = useState(false)


  const handleSubmit = async () => {

    const isFormValid = validateForm()

    if (!isFormValid) {
      return
    }

    setLoading(true)


    try {
      await requestPasswordRecovery(form.values)

      setErrors([])
      setSuccess(true)
      setLoading(false)

      return

    } catch (err) {
      const responseErrors = (await err.json())?.errors

      setErrors(responseErrors || ['network error'])
      setLoading(false)
    }
  }


  if (userToken) {
    return (<Redirect to="/" />)
  }


  return (
    <div className="container-fluid mt-3 mt-lg-5 py-5">
      <div className="row">
        <div className="mx-auto col-md-6 col-lg-5">
          <div className="text-center mb-3 mb-lg-5">
            <Link to="/">
              <AppLogo />
            </Link>
          </div>
          <h4 className="text-center mb-4">
            Welcome to Medvertise
          </h4>

          <div className="bg-white p-3 p-lg-4 rounded shadow-sm">
            <h5 className="text-center">
              Password Recovery
            </h5>

            <RequestPasswordRecoveryForm
              fields={form.fields}
              isValid={form.isValid}
              errors={errors}
              isLoading={isLoading}
              hasSuccess={hasSuccess}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
