import PropTypes from 'prop-types'

import { getFormattedNumber } from '../../helpers/text'

export const Notifications = ({ notifications }) => (
  <div className="list-group">

    {notifications.map(notification => (
      <div
        key={notification.product.id}
        className="list-group-item"
      >
        <div>
          <div>
            <a href={notification.product.url} target="_blank" rel="noreferrer" className="h3">
              {notification.product.title}
            </a>
            <span className="ms-3 fs-4 fw-bold">${getFormattedNumber(notification.product.price)}</span>
          </div>
          {
            notification.items.map(item => (
              <div key={item.id} className="border-top ms-2 ps-1">
                <span className="h5">${getFormattedNumber(item.price)}</span>
                <span>{item.uri}</span> - <span>{item.cond}</span> - <span>{item.updated}</span>
              </div>
            ))
          }
        </div>
      </div>
    ))}

  </div>
)


Notifications.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired
}
