import React from 'react'

import { ThemeProvider } from '@material-ui/core/styles'

import { Router } from './Router'

import routes from './config/routes'
import { ModalProvider, UnitsProvider, UserProvider } from './contexts'
import theme from './theme'


const Providers = ({ children }) => (
  <ThemeProvider theme={theme}>
    <ModalProvider>
      <UnitsProvider>
        <UserProvider>
          {children}
        </UserProvider>
      </UnitsProvider>
    </ModalProvider>
  </ThemeProvider>
)


export const App = () => (
  <Providers>
    <Router routes={routes} />
  </Providers>
)
