import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { useUser } from './contexts'

import { PrivateRoute } from './components/PrivateRoute'


export const Router = ({ routes }) => {

  const { hasPermissions } = useUser()

  const getRoute = (route, index) => {

    const RouteComponent = route.private ? PrivateRoute : Route

    if (!hasPermissions(route.permissions)) {
      return null
    }


    return (
      <RouteComponent path={route.path} key={index}>
        {route.component}
      </RouteComponent>
    )
  }


  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        {routes.map(getRoute)}
      </Switch>
    </BrowserRouter>
  )

}
