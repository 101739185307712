import { getSortingComparer, getSorted } from '../../../helpers'


export const sortingOptions = [
  {
    label: 'Most Recent',
    value: getSortingComparer('desc', 'publishDate')
  },
  {
    label: 'Category',
    value: getSortingComparer('asc', 'productCategory')
  },
  {
    label: 'Recently Updated',
    value: getSortingComparer('desc', 'updatedDate')
  },
  {
    label: 'Oldest',
    value: getSortingComparer('asc', 'publishDate')
  }
]


export const getSortedUnits = (unsortedUnits, optionIndex) => {
  const sortingComparer = sortingOptions[optionIndex].value

  const sorted = getSorted(unsortedUnits, sortingComparer)

  return getSorted(sorted, function (a, b) {
    const isPendingA = (a.status === 'pending')
    const isPendingB = (b.status === 'pending')

    if (isPendingA === isPendingB) {
      return 0
    }

    if (isPendingB) {
      return 1
    }

    if (isPendingA) {
      return -1
    }

    return 0
  })
}
