import React from 'react'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'


import { stripHtml } from '../../helpers'

import {
  FormField, HeadingDivider, ImageSelector, LengthLimit, SubmitButton
} from '..'

import { conditionsOptions, modalityOptions } from './product-fields'
import { hasNewProductTerms, ProductField } from './ProductField'
import { ModalityFields } from './ModalityFields'


const commonProps = {
  fullWidth: true,
  margin: 'dense',
  variant: 'filled'
}


export const InsertUnit = ({ fields, isFormValid, isLoading, onSubmit, availableProducts, getTerms }) => {

  function onProductDescriptionPaste() {
    setTimeout(() => {
      fields.productDescription.setValue(stripHtml(fields.productDescription.value))
    }, 0)
  }

  const isEnabled = isFormValid && hasNewProductTerms(fields.product.value)


  return (
    <>
      <HeadingDivider title="Product Features" />

      <FormField label="Model Name *">
        <ProductField
          {...commonProps}
          error={!fields.product.isValid}
          {...fields.product.getProps()}
          availableProducts={availableProducts}
          getTerms={getTerms}
        />
      </FormField>
      <FormField label="Title Description">
        <TextField
          {...commonProps}
          error={!fields.title.isValid}
          {...fields.title.getProps()}
        />
        <LengthLimit
          value={fields.title.value}
          limit={fields.title.attributes.maxLength}
        />
      </FormField>
      <FormField label="Product Description *">
        <TextField
          {...commonProps}
          error={!fields.productDescription.isValid}
          multiline
          minRows="3"
          maxRows="6"
          onPaste={onProductDescriptionPaste}
          {...fields.productDescription.getProps()}
        />
      </FormField>
      <FormField label="Condition *">
        <Select
          {...commonProps}
          displayEmpty
          error={!fields.condition.isValid}
          {...fields.condition.getProps()}
        >
          <MenuItem key="" value=""><span style={{ color: 'gray' }}>Select a Condition</span></MenuItem>
          {conditionsOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>{label}</MenuItem>
          ))}
        </Select>
      </FormField>
      <FormField>
        <RadioGroup
          row
          {...fields.modality.getProps()}
        >
          {modalityOptions.map(({ value, label }) => (
            <FormControlLabel
              key={value}
              value={value}
              label={<Typography color={(fields.modality.isValid) ? 'inherit' : 'error'}>{label}</Typography>}
              control={<Radio color="primary" />}
              labelPlacement="end"
            />
          ))}
        </RadioGroup>
      </FormField>
      <div className="row mb-3">
        <div className="col-sm-3 pt-sm-2 mb-1 text-sm-end small lh-sm">
          Price *
        </div>
        <div className="col-sm-3 mb-3 mb-sm-0">
          <TextField
            {...commonProps}
            error={!fields.price.isValid}
            {...fields.price.getProps()}
            InputProps={{
              endAdornment: <InputAdornment position="end">USD</InputAdornment>,
            }}
          />
        </div>
        <div className="col-sm-3 mb-1 text-sm-end small lh-sm">
          Year of Manufacture
        </div>
        <div className="col-sm-3">
          <TextField
            {...commonProps}
            error={!fields.year.isValid}
            {...fields.year.getProps()}
          />
        </div>
      </div>
      <FormField label="Image">
        <ImageSelector setMedia={fields.media.setValue} />
      </FormField>

      <FormField label="Product Location">
        <TextField
          {...commonProps}
          error={!fields.shippingLocation.isValid}
          {...fields.shippingLocation.getProps()}
        />
      </FormField>

      <ModalityFields
        fields={fields}
        commonProps={commonProps}
      />

      <div style={{ marginTop: '2rem', display: 'flex', 'justifyContent': 'flex-end' }}>
        <SubmitButton onClick={onSubmit} isLoading={isLoading} enabled={isEnabled}>
          Publish Product
        </SubmitButton>
      </div>
    </>
  )
}
