import PropTypes from 'prop-types'

import { ImagePreview } from '..'


export const Companies = ({ companies, onSelectCompany }) => (
  <div className="list-group">

    {companies.map(company => (
      <button
        key={company.id}
        type="button"
        className="list-group-item list-group-item-action"
        onClick={() => onSelectCompany(company.id)}
      >
        <div className="d-flex align-items-center gap-3">
          <ImagePreview
            src={company?.iconUrl}
            width={64}
            height={64}
          />
          <h3 className='mb-0'>{company.name}</h3>
        </div>
      </button>
    ))}

  </div>
)


Companies.propTypes = {
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelectCompany: PropTypes.func.isRequired
}
