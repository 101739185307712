export class InputField {

  /**
   * @param {Object} properties
   * @param {Array} properties.validators
   */
  constructor(properties = {}) {
    const { name, value, onChange, validators, ...attributes } = properties

    this.attributes = attributes

    this.name = name || null
    this.value = (typeof value === 'undefined') ? '' : value
    this.onChange = onChange || (() => { })
    this.validators = validators || []

    this.touched = false
    this.isValid = true
  }


  getProps = () => ({
    value: this.value,
    name: this.name,
    onBlur: this.onBlur,
    onChange: this.handleChange
  })


  setValue = value => {
    this.value = value

    if (this.touched) {
      this.validate()
    }

    this.onChange(this)
  }


  handleChange = (input, value) => {
    if ((typeof value !== 'undefined') && (typeof value !== 'object')) {
      this.setValue(String(value))
      return
    }

    if (value === null) {
      this.setValue('')
      return
    }

    if (input && input.target) {
      this.setValue(input.target.value)
      return
    }

    this.setValue(input)
  }


  onBlur = event => {
    this.touched = true
    this.validate()
    this.onChange(this)
  }


  validate = () => {
    this.isValid = (this.getFirstFailedValidator() === null)
  }


  getFirstFailedValidator = () => {
    if (this.validators.length === 0) {
      return null
    }

    for (const validator of this.validators) {
      const validatorResult = validator(this.value)

      if (validatorResult !== true) {
        return validatorResult
      }
    }

    return null
  }

}
