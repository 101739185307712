import React, { useLayoutEffect } from 'react'


export const ModalBackDrop = () => {

  useLayoutEffect(() => {
    const scrollbarWidth = window.innerWidth - document.documentElement.offsetWidth

    document.documentElement.style
      .setProperty('--scrollbar-width', `${scrollbarWidth}px`)


    document.body.classList.add('body-with-backdrop')

    return () => {
      document.body.classList.remove('body-with-backdrop')
    }
  }, [])


  return (
    <div className="modal-backdrop fade show"></div>
  )
}
