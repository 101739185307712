import React, { useState, createContext, useContext } from 'react'
import { Modal } from '../components/specialized-components'


const ModalContext = createContext()
ModalContext.displayName = 'ModalContext'


export const useModal = () => {
  const { openModal } = useContext(ModalContext)

  return {
    openModal
  }
}


export const ModalProvider = ({ children }) => {
  const [modalContext, setModalContext] = useState({
    isOpen: false
  })

  const openModal = modal => new Promise(resolve => {
    const closeModal = result => {
      setModalContext({
        isOpen: false
      })

      resolve(result)
    }

    setModalContext({
      isOpen: true,
      onClose: closeModal,
      ...modal
    })
  })


  return (
    <ModalContext.Provider value={{ setModalContext, openModal }}>
      {children}
      <Modal {...modalContext} />
    </ModalContext.Provider>
  )
}
