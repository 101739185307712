import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'

import { HtmlEditor } from '../specialized-components'

import {
  FormField, HeadingDivider, LengthLimit,
  SubmitButton
} from '..'


const commonProps = {
  fullWidth: true,
  margin: 'dense',
  variant: 'filled'
}


export const UserForm = ({ fields, isFormValid, isLoading, onSubmit }) => (
  <>
    <HeadingDivider title="Seller Information" />

    <FormField label="Name *">
      <TextField
        {...commonProps}
        error={!fields.companyName.isValid}
        {...fields.companyName.getProps()}
      />
      <LengthLimit
        value={fields.companyName.value}
        limit={fields.companyName.attributes.maxLength}
      />
    </FormField>

    <FormField label="Description">
      <HtmlEditor {...fields.companyDescription.getProps()} />

      <LengthLimit
        value={fields.companyDescription.value}
        limit={fields.companyDescription.attributes.maxLength}
      />
    </FormField>

    <FormField label="Address">
      <TextField
        {...commonProps}
        error={!fields.companyAddress.isValid}
        {...fields.companyAddress.getProps()}
      />
    </FormField>

    <FormField label="Website URL">
      <TextField
        {...commonProps}
        type="url"
        error={!fields.companyWebsiteUrl.isValid}
        {...fields.companyWebsiteUrl.getProps()}
      />
    </FormField>

    <HeadingDivider title="Contact" />

    <FormField label="Name *">
      <TextField
        {...commonProps}
        error={!fields.companyContactName.isValid}
        {...fields.companyContactName.getProps()}
      />
    </FormField>

    <FormField label="Telephone *">
      <TextField
        {...commonProps}
        error={!fields.companyContactPhone.isValid}
        {...fields.companyContactPhone.getProps()}
      />
    </FormField>

    <FormField label="Email *">
      <TextField
        {...commonProps}
        type="email"
        error={!fields.companyContactEmail.isValid}
        {...fields.companyContactEmail.getProps()}
      />
      <p className="small fst-italic">You will receive your leads here.<br />
        This Email Address is not displayed anywhere.</p>
    </FormField>

    <div className="mt-4 d-flex justify-content-end">
      <SubmitButton onClick={onSubmit} isLoading={isLoading} enabled={isFormValid}>
        Update User
      </SubmitButton>
    </div>
  </>
)


UserForm.propTypes = {
  fields: PropTypes.object,
  isFormValid: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
}
