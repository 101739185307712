import React from 'react'
import PropTypes from 'prop-types'

import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import DeleteIcon from '@material-ui/icons/Delete'

import { useModal } from '../../contexts'
import { stripHtml } from '../../helpers'

import {
  getAlert, Button, FormField, ImageSelector, LengthLimit,
  SubmitButton
} from '..'

import { ModalityFields } from './ModalityFields'


const commonProps = {
  fullWidth: true,
  margin: 'dense',
  variant: 'filled'
}


export const EditUnit = ({ fields, isFormValid, isLoading, onSubmit, onDelete }) => {
  const { openModal } = useModal()


  function onProductDescriptionPaste() {
    setTimeout(() => {
      fields.productDescription.setValue(stripHtml(fields.productDescription.value))
    }, 0)
  }

  const notifyInvalidFile = message => {
    openModal(
      getAlert(message)
    )
  }


  return (
    <>
      <FormField label="Title Description">
        <TextField
          {...commonProps}
          error={!fields.title.isValid}
          {...fields.title.getProps()}
        />
        <div className="d-flex justify-content-between gap-1">
          <small className="text-muted">
            {fields.title.attributes.description}
          </small>
          <LengthLimit
            value={fields.title.value}
            limit={fields.title.attributes.maxLength}
          />
        </div>
      </FormField>
      <FormField label="Product Description *">
        <TextField
          {...commonProps}
          error={!fields.productDescription.isValid}
          multiline
          minRows="3"
          maxRows="6"
          onPaste={onProductDescriptionPaste}
          {...fields.productDescription.getProps()}
        />
      </FormField>

      <FormField label="Price *">
        <TextField
          {...commonProps}
          error={!fields.price.isValid}
          {...fields.price.getProps()}
          InputProps={{
            endAdornment: <InputAdornment position="end">USD</InputAdornment>,
          }}
        />
      </FormField>

      <FormField label="Image">
        <ImageSelector
          setMedia={fields.media.setValue}
          notifyInvalidFile={notifyInvalidFile}
        />
      </FormField>

      <FormField label="Product Location">
        <TextField
          {...commonProps}
          error={!fields.shippingLocation.isValid}
          {...fields.shippingLocation.getProps()}
        />
      </FormField>

      <ModalityFields
        fields={fields}
        commonProps={commonProps}
      />

      <div style={{ marginTop: '2rem', display: 'flex', 'justifyContent': 'flex-end' }}>
        <SubmitButton onClick={onSubmit} isLoading={isLoading} enabled={isFormValid}>
          Update Unit
        </SubmitButton>
      </div>

      <hr className="mt-5" />

      <Button
        onClick={onDelete}
        variant="outline-danger"
      >
        <span className="d-flex align-items-center">
          <DeleteIcon style={{ fontSize: '1.2em' }} className="me-1" />Delete Unit
        </span>
      </Button>
    </>
  )
}


EditUnit.propTypes = {
  fields: PropTypes.object,
  isFormValid: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}
