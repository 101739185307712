import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { AutocompleteDropdown } from '../../specialized-components'

import { ProductNameField } from './ProductNameField'


const TaxonomyField = ({ taxonomyTitle, options, onChange, error }) => (
  <div className="mb-3 d-sm-flex gap-2 align-items-center">
    <span className={`"small ${error && 'text-danger'}`}>
      Product {taxonomyTitle}:
    </span>
    <AutocompleteDropdown
      onChange={onChange}
      options={options}
      placeholder={`Select ${taxonomyTitle}`}
      className="flex-grow-1"
    />
  </div>
)


export function ProductField({ value, onChange, availableProducts, getTerms, ...props }) {

  const isNewProduct = ((value.id === null) && value.title)

  const [terms, setTerms] = useState(null)

  useEffect(() => {
    if (terms || !isNewProduct) {
      return
    }

    getTerms()
      .then(providedTerms => {
        setTerms(providedTerms)
      })
  })

  const setTaxonomy = taxonomy => {
    onChange({
      ...value,
      ...taxonomy
    })
  }


  return (<>
    <ProductNameField
      value={value}
      setValue={onChange}
      availableProducts={availableProducts}
      {...props}
    />

    {(isNewProduct && !!terms) && (
      <div className="mt-3">
        <TaxonomyField
          taxonomyTitle="Manufacturer"
          onChange={term => setTaxonomy({ manufacturerId: term.id })}
          options={terms.manufacturers}
          error={!value.manufacturerId}
        />
        <TaxonomyField
          taxonomyTitle="Category"
          onChange={term => setTaxonomy({ categoryId: term.id })}
          options={terms.categories}
          error={!value.categoryId}
        />
        <TaxonomyField
          taxonomyTitle="Type"
          onChange={term => setTaxonomy({ typeId: term.id })}
          options={terms.types}
          error={!value.typeId}
        />
      </div>
    )}
  </>)
}


ProductField.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  availableProducts: PropTypes.array.isRequired,
  getTerms: PropTypes.func.isRequired
}
