import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'


export const CheckboxField = ({ value, setValue, label, ...props }) => {

  const handleCheckboxChange = event => {
    setValue(event.target.checked)
  }


  return (
    <FormControlLabel
      value="end"
      control={<Checkbox
        checked={value}
        onChange={handleCheckboxChange}
      />}
      label={label}
      labelPlacement="end"
      {...props}
    />
  )
}


CheckboxField.propTypes = {
  value: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string
}
