/**
 * @param {Object[]} entries
 * @param {Function} comparer
 * @returns {Object[]}
 */
export function getSorted(entries, comparer) {
  const stabilizedThis = entries.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparer(a[0], b[0])

    if (order !== 0) {
      return order
    }

    return a[1] - b[1]
  })

  return stabilizedThis.map(el => el[0])
}

/**
 * @param {string} order
 * @param {string} orderBy
 * @returns {function}
 */
export const getSortingComparer = (order, orderBy) =>
  (order === 'desc') ? (a, b) => comparerByProp(a, b, orderBy) : (a, b) => -comparerByProp(a, b, orderBy)


function comparerByProp(a, b, orderBy) {
  const valueA = a[orderBy] || null
  const valueB = b[orderBy] || null

  if (valueB < valueA) {
    return -1
  }

  if (valueB > valueA) {
    return 1
  }

  return 0
}


