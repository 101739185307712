import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import AddIcon from '@material-ui/icons/Add'
import RouterTwoToneIcon from '@material-ui/icons/RouterTwoTone'


import { Units as UnitsComponent, PromoteDeviceForm, Toolbar } from '../components/Units'
import { getFilteredUnits, getSortedUnits } from '../components/Units/helpers'
import { Button, Loader } from '../components/ui-blocks'
import { Modal } from '../components/specialized-components'
import { getUnits, promoteUnit } from '../helpers/mpo-wp-api'

import { useUnits, useUser } from '../contexts'


const getParsedUnits = units => units.map(unit => ({
  ...unit,
  productCategory: unit.product.categoryName
}))


/**
 * @param {object[]} units
 * @param {Number} filterOptionIndex
 * @param {Number} sortingOptionIndex
 * @returns object[]
 */
const getVisibleUnits = (units, filterOptionIndex, sortingOptionIndex) =>
  getSortedUnits(
    getFilteredUnits(units, filterOptionIndex),
    sortingOptionIndex
  )


export const Units = () => {
  const { units, setUnits, selectedUnits, setSelectedUnits } = useUnits()

  const [visibleUnits, setVisibleUnits] = useState()
  const [filterOptionIndex, setFilterOptionIndex] = useState(0)
  const [sortingOptionIndex, setSortingOptionIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [isOpen, setOpen] = useState(false)
  const [unitsToPromote, setUnitsToPromote] = useState([])

  const { balance } = useUser()


  const loadUnits = () => {
    setIsLoading(true)

    getUnits()
      .then(results => {
        const parsedUnits = getParsedUnits(results)

        setUnits(parsedUnits)
        setVisibleUnits(getVisibleUnits(parsedUnits, filterOptionIndex, sortingOptionIndex))

        setIsLoading(false)
      })
  }


  useEffect(() => {
    if (Array.isArray(units)) {
      setVisibleUnits(getVisibleUnits(units, filterOptionIndex, sortingOptionIndex))
      return
    }

    loadUnits()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const handleFilterChange = event => {
    const index = event.target.value

    setFilterOptionIndex(index)
    setVisibleUnits(getVisibleUnits(units, index, sortingOptionIndex))
  }

  const handleSortingChange = event => {
    const index = event.target.value

    setSortingOptionIndex(index)
    setVisibleUnits(getVisibleUnits(units, filterOptionIndex, index))
  }


  const handlePromoteDeviceRequest = async (ids, { budget }) => {
    setIsLoading(true)

    await promoteUnit(ids.join(), { budget })

    setIsLoading(false)
    setOpen(false)

    window.location.reload()
  }


  const openPromoteDeviceModal = unitsAssignedToPromote => {
    setOpen(true)
    setUnitsToPromote(unitsAssignedToPromote)
  }

  const handleCloseModal = () => {
    if (!isLoading) {
      setOpen(false)
    }
  }


  const handleBulkPromotion = () => {
    openPromoteDeviceModal(selectedUnits)
  }


  if (!Array.isArray(visibleUnits)) {
    return (
      <div className="mt-5 pt-5 text-center">
        <Loader className="my-3" fullWidth />
        <h6>Loading...</h6>
      </div>
    )
  }


  if (units.length === 0) {
    return (
      <div className="mt-5 pt-4 text-center">
        <RouterTwoToneIcon className="my-4 text-muted" style={{ fontSize: '5rem' }} />

        <h3 className="mb-4">Create Your First Product Listing</h3>

        <Link to="/publish-product">
          <Button variant="primary" className="btn-lg rounded-2">
            <AddIcon className="me-1 fs-3" />
            Add Unit
          </Button>
        </Link>

      </div>
    )
  }


  const canPromote = (balance > 0)

  const handlePromoteDevice = canPromote ? openPromoteDeviceModal : null

  return (<>
    <div className="mt-5 pt-4">
      <Toolbar
        canPromote={canPromote}
        handleBulkPromotion={handleBulkPromotion}
        isLoading={isLoading}
        loadUnits={loadUnits}
        selectedUnits={selectedUnits}
        handleFilterChange={handleFilterChange}
        filterOptionIndex={filterOptionIndex}
        handleSortingChange={handleSortingChange}
        sortingOptionIndex={sortingOptionIndex}
      />

      <div className="container-fluid mx-auto">
        {(visibleUnits.length) ? (
          <UnitsComponent
            units={visibleUnits}
            selectedUnits={selectedUnits}
            setSelectedUnits={setSelectedUnits}
            handlePromoteDevice={handlePromoteDevice}
          />
        ) : (
          <div className="text-center">
            <h3 className="mt-5 mb-3">No Unit matching current Filters</h3>
            <Button variant="primary" onClick={() => handleFilterChange({ target: { value: 0 } })}>
              Display all Units
            </Button>
          </div>
        )}

      </div>
    </div>

    <Modal isOpen={isOpen} onClose={handleCloseModal}
      title="Promote this device on Google"
      body={
        <PromoteDeviceForm
          units={unitsToPromote}
          isLoading={isLoading}
          balance={balance}
          onPromoteDevice={handlePromoteDeviceRequest}
        />
      }
    />

  </>)
}
