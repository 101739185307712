const localStorageUserKey = process.env.REACT_APP_USER_TOKEN_KEY


export const decodeJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}


export const getUserTokenLocalStorage = () => localStorage.getItem(localStorageUserKey)

export const setUserTokenLocalStorage = token => localStorage.setItem(localStorageUserKey, token)

export const logoutUser = () => setUserTokenLocalStorage(null)

export const redirectToLogin = () => {
  window.location = `${process.env.PUBLIC_URL}/login`
}
