import React from 'react'

import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'

import { conditionsOptions, modalityOptions, getLabelByKey } from './product-fields'

import { ImagePreview } from '..'


const mpoColors = {
  title: '#399',
  rent: '#f90',
  sale: '#09f'
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.divider,
    color: 'rgb(51, 51, 51)'
  },
  title: {
    color: mpoColors.title,
    fontWeight: 'bold'
  }
}))


export const MedicalPriceOnlineSnippet = ({ product, companyName }) => {

  const classes = useStyles()

  const condition = (product.condition === 'refurbished') ? 'Refurbished ' : ''
  const isRental = (product.modality === 'rent')


  return (
    <Box className={classes.root} p={1} border={1} display="flex">
      <Box mr={1}>
        <ImagePreview
          src={product.media?.previewUrl}
          width={120}
          height={120}
        />
      </Box>
      <Box flexGrow={1}>
        <Typography variant="body1" className={classes.title}>
          {product.year} {condition}
          {product.product.title || (<span className="snippet-field-placeholder">Model Name</span>)}
          {product.title && ` ${product.title}`}
          {product.shippingLocation && ` - ${product.shippingLocation}`}
        </Typography>
        <Typography variant="body1">
          <strong>Price:</strong> {Number(product.price) ?
            <span>${Number(product.price).toLocaleString()}</span>
            : <span className="snippet-field-placeholder">Insert Price</span>
          }
        </Typography>
        <Typography variant="body1">
          <strong>Unit Condition:</strong> {product.condition ?
            <span>{getLabelByKey(product.condition, conditionsOptions)}</span>
            : <span className="snippet-field-placeholder">Select Product Condition</span>
          }
        </Typography>
        <Collapse in={!isRental && (Number(product.shippingTimeframe) > 0)}>
          <Typography variant="body1">
            <strong>Shipping Timeframe:</strong> {Number(product.shippingTimeframe).toLocaleString()} days
          </Typography>
        </Collapse>
        <Typography variant="body1">
          <strong>Contact:</strong> {companyName}
        </Typography>
      </Box>
      <Box justifyContent="flex-end" textAlign="right">
        <Typography variant="body1" style={{
          color: mpoColors[product.modality]
        }}>
          <strong>ITEM {String(getLabelByKey(product.modality, modalityOptions)).toUpperCase()}</strong>
        </Typography>
      </Box>
    </Box>
  )
}
