import { isRequired, isValidBasicUrl, isValidMail, maxLength } from '../../helpers/Form'


export const formFields = {
  companyName: {
    maxLength: 60,
    validators: [isRequired, maxLength(60)]
  },
  companyDescription: {
    maxLength: 2000,
    validators: [maxLength(2000)]
  },
  companyAddress: {
  },
  companyWebsiteUrl: {
    validators: [isValidBasicUrl]
  },
  companyContactName: {
    validators: [isRequired]
  },
  companyContactPhone: {
    validators: [isRequired]
  },
  companyContactEmail: {
    validators: [isRequired, isValidMail]
  }
}
