export function getPriceRange(unitsCount) {
    const [min, max] = [unitsCount, 10 * unitsCount]

    const continueRange = Array(max - min + 1).fill(min).map((x, y) => x + y)

    const priceRangeSteps = continueRange.filter(price => (price % unitsCount) === 0)


    return {
        min,
        max,
        priceRangeSteps
    }
}
