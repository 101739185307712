import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'


const filter = createFilterOptions()

/**
 * @param {Array} options - The array of options to filter.
 * @returns {Array} The filtered array of options.
 */
const filterOptions = (options, params) => {
  const filtered = filter(options, params)


  const normalizedInput = String(params.inputValue).toLowerCase().trim()

  const isExistingProduct = !!options.find(({ title }) => normalizedInput === title.toLowerCase())

  if ((params.inputValue !== '') && !isExistingProduct) {
    filtered.push({
      inputValue: params.inputValue,
      title: `Add "${params.inputValue}" Product`,
    })
  }

  return filtered
}


const getOptionLabel = option => {
  if (typeof option === 'string') {
    return option
  }

  if (option.inputValue) {
    return option.inputValue
  }

  return option.title
}


export function ProductNameField({ setValue, availableProducts, ...props }) {
  const [inputValue, setInputValue] = useState('')
  const [open, setOpen] = useState(false)


  const handleInputChange = (event, newValue) => {
    setInputValue(newValue)
  }


  const handleChange = (event, newValue) => {
    if (typeof newValue === 'string') {
      setValue({
        id: null,
        title: newValue,
      })

      return
    }

    if (newValue && newValue.inputValue) {
      setValue({
        id: null,
        title: newValue.inputValue
      })
      return
    }


    if (newValue) {
      setValue(newValue)
      return
    }

    setValue({
      id: null,
      title: null
    })
  }

  const disabledOptions = availableProducts.filter(
    product => product.hasOwnershipPermission === false
  )


  return (
    <Autocomplete
      autoComplete
      filterOptions={filterOptions}
      freeSolo
      getOptionLabel={getOptionLabel}
      handleHomeEndKeys
      includeInputInList
      open={open && inputValue.length >= 2}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={availableProducts}
      getOptionDisabled={option => disabledOptions.includes(option)}
      popupIcon={null}
      renderInput={params => (
        <TextField
          {...props}
          {...params}
        />
      )}
      renderOption={option => option.title}
    />
  )
}


ProductNameField.propTypes = {
  setValue: PropTypes.func.isRequired,
  availableProducts: PropTypes.array.isRequired
}
