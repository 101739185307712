import React from 'react'

import Typography from '@material-ui/core/Typography'

import { CarouselProvider, Slider, Slide, DotGroup } from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'


import { makeStyles } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  img: {
    borderRadius: '.5rem',
    boxShadow: '0 0.5rem 1rem rgba(0,0,0,.25)',
    marginBottom: theme.spacing(2),
    maxWidth: '100%'
  },
  slide: {
    [theme.breakpoints.only('xs')]: {
      minHeight: '400px'
    }
  }
  ,
  [theme.breakpoints.up('sm')]: {
    slider: {
      height: '95%'
    },
    img: {
      marginBottom: theme.spacing(10),
    }
  },
  dotGroup: {
    display: 'flex',
    justifyContent: 'center',
    '& .carousel__dot': {
      background: 'transparent',
      borderRadius: '50%',
      border: `2px solid ${theme.palette.primary.main}`,
      display: 'inline-block',
      height: '.75rem',
      margin: '0 1rem',
      padding: '0',
      width: '.75rem'
    },
    '& .carousel__dot--selected': {
      background: theme.palette.primary.main
    }
  }
}))


export const Carousel = ({ slides }) => {

  const classes = useStyles()

  return (
    <CarouselProvider
      className={classes.root}
      naturalSlideWidth={100}
      naturalSlideHeight={50}
      totalSlides={slides.length}
    >
      <Slider className={classes.slider}>
        {slides.map(({ imageUrl, title, text }, i) => (
          <Slide index={i} key={i} className={classes.slide}>
            {imageUrl && (
              <img className={classes.img}
                src={`${process.env.PUBLIC_URL}/slides/${imageUrl}`}
                alt="Medvertise Logo" />
            )}
            <Typography variant="h5">
              {title}
            </Typography>
            <Typography variant="body2">
              {text}
            </Typography>
          </Slide>
        ))}
      </Slider>
      <DotGroup className={classes.dotGroup} />
    </CarouselProvider>
  )

}
