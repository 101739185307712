import { Companies, EditUnit, EditUser, Login, Notifications, InsertUnit, Units } from '../pages'
import { RequestPasswordRecovery, SetPassword } from '../pages/Login/'
import { withFooter, withTopBarAndFooter } from '../pages/layouts'

import { PERMISSIONS } from './permissions'


const defaultRoute = {
  private: true,
  permissions: []
}


const routes = [
  {
    path: '/login/forgot-password',
    component: withFooter(<RequestPasswordRecovery />),
    private: false
  },
  {
    path: '/login/set-password',
    component: withFooter(<SetPassword />),
    private: false
  },
  {
    path: '/login',
    component: <Login />,
    private: false
  },
  {
    path: '/companies',
    component: withFooter(<Companies />),
    permissions: [PERMISSIONS.ADMIN]
  },
  {
    path: '/notifications',
    component: withTopBarAndFooter(<Notifications />)
  },
  {
    path: '/unit/:id',
    component: withTopBarAndFooter(<EditUnit />)
  },
  {
    path: '/publish-product',
    component: withTopBarAndFooter(<InsertUnit />)
  },
  {
    path: '/profile',
    component: withTopBarAndFooter(<EditUser />)
  },
  {
    path: '/',
    component: withTopBarAndFooter(<Units />)
  }
]


export default routes.map(route => ({ ...defaultRoute, ...route }))
