import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { useUser } from '../contexts/user'


export const PrivateRoute = ({ children, ...rest }) => {
  const { userToken } = useUser()

  return (
    <Route
      {...rest}
      render={({ location }) =>
        userToken ? (
          children
        ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
          )
      }
    />
  )
}
