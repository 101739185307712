import React, { useLayoutEffect, useState } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'


import { GoogleShoppingSnippet, EditUnit as EditUnitComponent, MedicalPriceOnlineSnippet } from '../components/Product'
import { getConfirmDelete } from '../components/specialized-components'
import { CheckboxField } from '../components/ui-blocks'

import { useModal, useUnits, useUser } from '../contexts'

import { getLabelFromValue, getParsedFormData, getDateTime, useForm } from '../helpers'
import { getUnit, updateUnit, deleteUnits } from '../helpers/mpo-wp-api'

import { formFields, conditionsOptions, modalityOptions } from '../components/Product/product-fields'


export const EditUnit = () => {
  const { openModal } = useModal()
  const { purgeUnits } = useUnits()
  const { userCompanyName } = useUser()

  const [form, setFormValues, validateForm] = useForm(formFields)
  const [isLoading, setLoading] = useState(false)
  const [unit, setUnit] = useState(null)

  const history = useHistory()

  const id = Number(useParams().id)

  useLayoutEffect(() => {
    getUnit(id)
      .then(results => {
        setUnit(results)
        setFormValues(results)
      })
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps


  const handleUpdate = async () => {
    const isFormValid = validateForm()

    if (!isFormValid) {
      return
    }

    setLoading(true)

    const updatedUnit = await updateUnit(id, getParsedFormData(form.values))
    setFormValues(updatedUnit)
    setUnit(updatedUnit)

    purgeUnits()

    setLoading(false)
  }

  if (!unit || !(Object.keys(form.values).length)) {
    return null
  }


  const handleDelete = async () => {
    if (await openModal(
      getConfirmDelete(<>You will delete the unit <strong>{unit.product.title}</strong>.</>)
    )) {
      await deleteUnits([unit.id])

      history.push('/')
    }
  }

  const isPending = (unit.status === 'pending')

  return (
    <Box className="container-fluid" mt={8} py={3} px={2}>

      <Box mb={3}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <Box display="flex">
              <Link to="/">
                <Box mt={1} mr={1} color="text.primary">
                  <ArrowBackIcon />
                </Box>
              </Link>
              <div>
                <Typography variant="h4">
                  {unit.product.title}
                </Typography>
                <Typography>
                  {getLabelFromValue(unit.condition, conditionsOptions)}&ensp;-&ensp;
                  {getLabelFromValue(unit.modality, modalityOptions)}
                </Typography>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box textAlign="right">
              <Typography>
                Published: <strong>{getDateTime(unit.publishDate)}</strong>
              </Typography>
              {unit.updatedDate &&
                <Typography>
                  Updated: <strong>{getDateTime(unit.updatedDate)}</strong>
                </Typography>
              }
            </Box>
          </Grid>
        </Grid>
      </Box>

      <div className="row g-5">
        <div className="col-lg-7 col-xl-6">
          <EditUnitComponent
            fields={form.fields}
            isFormValid={form.isValid}
            isLoading={isLoading}
            onSubmit={handleUpdate}
            onDelete={handleDelete}
          />
        </div>

        <div className="col-lg-5 col-xl-6">
          <div className="d-flex flex-column gap-5 sticky-top sticky-stacked-top">
            <div>
              <div className="d-flex ms-1 justify-content-between align-items-center">
                <h5 className="ms-1">Medical Price Online Preview</h5>

                {!isPending &&
                  <Button href={unit.url} variant="text" color="primary" target="_blank" rel="noopener">
                    View
                    <OpenInNewIcon style={{ fontSize: '1rem', marginLeft: '.25rem' }} />
                  </Button>
                }
              </div>

              <MedicalPriceOnlineSnippet
                companyName={userCompanyName}
                product={form.values}
              />
            </div>

            <div>

              <div className="d-flex ms-1 justify-content-between align-items-center">
                <h5 className="mb-0">Google Shopping Preview</h5>

                <CheckboxField
                  value={form.fields.skipPublishing.value}
                  setValue={form.fields.skipPublishing.setValue}
                  label="Don't Publish"
                />
              </div>

              <GoogleShoppingSnippet product={form.values} />
            </div>

          </div>
        </div>

      </div>
    </Box>
  )
}
