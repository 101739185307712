import React from 'react'


export const HeadingDivider = ({ title }) => (

  <>
    <div className="row spacer-between">
      <div className="col-sm-9 offset-sm-3">
        <h5 className="mb-0">
          {title}
        </h5>
      </div>
    </div>
    <hr className="mt-1 mb-3" />
  </>

)

