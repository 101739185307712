import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'

import {
    FormField, HeadingDivider
} from '..'


export function ModalityFields({ fields, commonProps = {} }) {
    const getShippingFields = () => (<>
        <HeadingDivider title="Shipping" />
        <FormField label="Shipping Timeframe">
            <TextField
                {...commonProps}
                type="number"
                error={!fields.shippingTimeframe.isValid}
                {...fields.shippingTimeframe.getProps()}
                InputProps={{
                    endAdornment: <InputAdornment position="end">days</InputAdornment>,
                }}
            />
        </FormField>
        <FormField label="Shipping Fee" validationMessage={fields.shippingFee.getFirstFailedValidator()}>
            <TextField
                {...commonProps}
                type="number"
                error={!fields.shippingFee.isValid}
                {...fields.shippingFee.getProps()}
                placeholder="Free"
                InputProps={{
                    endAdornment: <InputAdornment position="end">USD</InputAdornment>,
                }}
            />
        </FormField>
    </>)


    switch (fields.modality.value) {
        case 'rent':
            return <>
                <HeadingDivider title="Service Details" />
                <FormField label="Service Region">
                    <TextField
                        {...commonProps}
                        error={!fields.serviceRegion.isValid}
                        {...fields.serviceRegion.getProps()}
                    />
                </FormField>
            </>

        case 'auction':
            return <>
                <HeadingDivider title="Auction Details" />
                <FormField label="Bid Listing URL *">
                    <TextField
                        {...commonProps}
                        error={!fields.bidListingUrl.isValid}
                        {...fields.bidListingUrl.getProps()}
                    />
                </FormField>
                {getShippingFields()}
            </>

        default:
            return getShippingFields()
    }
}
