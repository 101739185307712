import { TopBar } from '../components/TopBar'
import { LegalLinks } from '../components/ui-blocks'


export const withFooter = children =>
    <>
        <div className="mb-3">
            {children}
        </div>
        <footer className="ps-2 small">
            <LegalLinks />
        </footer>
    </>


export const withTopBarAndFooter = children =>
    <>
        <TopBar />
        {withFooter(children)}
    </>
