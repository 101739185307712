import React from 'react'
import PropTypes from 'prop-types'


export const StyleButton = ({ label, style, active, onToggle, icon }) => {

  const handleToggle = e => {
    e.preventDefault()
    onToggle(style)
  }


  const stateClassName = (active ? 'btn-dark' : 'btn-outline-dark')

  return (
    <button
      type="button"
      className={`btn me-1 py-1 px-2 ${stateClassName}`}
      onMouseDown={handleToggle}
      aria-label={label}
    >
      {icon || (<strong>{label}</strong>)}
    </button>
  )
}


StyleButton.propTypes = {
  label: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  icon: PropTypes.node
}
