import React, { useState } from 'react'
import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'


export function AutocompleteDropdown({ onChange, options, className = '', ...props }) {
  const [inputValue, setInputValue] = useState('')


  const handleInputChange = (event, newValue) => {
    setInputValue(newValue)
  }

  const handleChange = (event, option) => {
    if (option) {
      onChange(option)
      return
    }

    onChange({
      id: null,
      title: null
    })
  }


  return (
    <Autocomplete
      inputValue={inputValue}
      className={className}
      disableClearable={true}
      getOptionLabel={option => option.title}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={options}
      renderInput={params => (
        <TextField
          {...props}
          {...params}
        />
      )}
    />
  )
}


AutocompleteDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
}
