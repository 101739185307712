import React, { useState, useLayoutEffect } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'

import Alert from '@material-ui/lab/Alert'

import { SetPasswordForm } from '../../components/Auth/SetPasswordForm'
import { setPasswordFields } from '../../components/Auth/auth-forms'
import { AppLogo } from '../../components/ui-blocks'

import { useUser } from '../../contexts/user'
import { useForm } from '../../helpers'
import { checkPasswordResetKey, setNewPassword } from '../../helpers/mpo-wp-api'


export const SetPassword = () => {

  const [form, setFormValues, validateForm] = useForm(setPasswordFields) // eslint-disable-line no-unused-vars

  const { userToken } = useUser()

  const [errors, setErrors] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [hasSuccess, setSuccess] = useState(false)
  const [isKeyValid, setIsKeyValid] = useState(null)

  const params = new URLSearchParams(useLocation().search)
  const key = params.get('key')
  const login = params.get('login')


  useLayoutEffect(() => {
    checkPasswordResetKey({ key, login })
      .then(() => {
        setIsKeyValid(true)
      })
      .catch(() => {
        setIsKeyValid(false)
      })
  }, [key, login])


  const handleSubmit = async () => {

    const isFormValid = validateForm()

    if (!isFormValid) {
      return
    }

    setLoading(true)


    try {
      await setNewPassword({ key, login, ...form.values })

      setErrors([])
      setSuccess(true)
      setLoading(false)

      return

    } catch (err) {
      const responseErrors = (await err.json())?.errors

      setErrors(responseErrors || ['network error'])
      setLoading(false)
    }
  }


  if (userToken) {
    return (<Redirect to="/" />)
  }


  let content = null


  if (isKeyValid === null) {
    return null
  }


  if (isKeyValid === false) {
    content = (<>
      <Alert severity="error" className="mb-3">
        The link you provided has expired.
      </Alert>
      <p>
        Do you want to <Link to="/login/forgot-password">
          recover your password
        </Link>?
      </p>
      <p>
        If you have your credentials, you can try to <Link to="/login">
          log in
        </Link>
      </p>
    </>
    )
  }


  if (!content) {
    content = (<>
      <SetPasswordForm
        fields={form.fields}
        isValid={form.isValid}
        errors={errors}
        isLoading={isLoading}
        hasSuccess={hasSuccess}
        onSubmit={handleSubmit}
      />
    </>)
  }


  return (
    <div className="container-fluid mt-5 py-5">
      <div className="row">
        <div className="mx-auto col-md-6 col-lg-5">
          <div className="text-center mb-3 mb-lg-5">
            <Link to="/">
              <AppLogo />
            </Link>
          </div>
          <h4 className="text-center mb-4">
            Welcome to Medvertise
          </h4>

          <div className="bg-white p-3 p-lg-4 rounded shadow-sm">
            <h5 className="text-center mb-3">
              Set New Password
            </h5>

            {content}
          </div>
        </div>
      </div>
    </div>
  )
}
