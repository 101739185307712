import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../../ui-blocks'


export const ModalDialog = ({ title = '', body, buttons, contentRef, onClose = () => { } }) => {

  const getButton = (props, index) => {
    const { action, label, className = '', ...rawButtonProps } = props

    const buttonProps = {
      onClick: () => onClose(action),
      variant: 'outline-primary',
      ...rawButtonProps
    }

    return (
      <Button className={`my-0 ${className}`} {...buttonProps} key={index}>{props.label}</Button>
    )
  }


  return (
    <div className="modal-dialog modal-lg">
      <div className="modal-content" ref={contentRef}>
        <div className="modal-header">
          {title && (<h5 className="modal-title">{title}</h5>)}

          <button
            onClick={() => onClose(null)}
            className="btn-close"
            type="button"
            aria-label="Close"
          ></button>
        </div>
        {body && (
          <div className="modal-body text-center lead">
            {body}
          </div>
        )}
        {buttons && (
          <div className="modal-footer d-flex flex-row-reverse justify-content-center">
            {buttons.map(getButton)}
          </div>
        )}
      </div>
    </div>
  )
}


ModalDialog.propTypes = {
  title: PropTypes.node,
  body: PropTypes.node,
  buttons: PropTypes.arrayOf(PropTypes.object)
}
