import React, { useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'


import { UserForm } from '../components/Users/UserForm'

import { useForm, getParsedFormData } from '../helpers'
import { getUser, updateUser } from '../helpers/mpo-wp-api'

import { formFields } from '../components/Users/user-fields'


export const EditUser = () => {
  const [form, setFormValues, validateForm] = useForm(formFields)
  const [isLoading, setLoading] = useState(false)
  const [user, setUser] = useState(null)

  useLayoutEffect(() => {
    getUser()
      .then(results => {
        setUser(results)
        setFormValues(results)
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const handleUpdate = async () => {
    const isFormValid = validateForm()

    if (!isFormValid) {
      return
    }

    setLoading(true)

    const updatedUser = await updateUser(getParsedFormData(form.values))
    setFormValues(updatedUser)
    setUser(updatedUser)

    setLoading(false)
  }


  if (!user || !(Object.keys(form.values).length)) {
    return null
  }


  return (
    <div className="container-fluid mt-5 py-5 mx-auto">

      <div className="d-sm-flex justify-content-between">
        <div className="d-flex align-items-center">
          <Link to="/">
            <div className="text-body mx-1">
              <ArrowBackIcon />
            </div>
          </Link>
          <h1 className="mb-0">User Settings</h1>
        </div>
        {user?.companyUrl && (
          <div>
            <a href={user?.companyUrl} className="btn btn-secondary" target="_blank" rel="noreferrer">
              View Public Page
            </a>
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-lg-10 col-xl-8 mx-auto">

          <UserForm
            fields={form.fields}
            isFormValid={form.isValid}
            isLoading={isLoading}
            onSubmit={handleUpdate}
          />

        </div>
      </div>

    </div>
  )
}
